import React from "react";
import Modal from "react-modal";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { IProductUUID } from "tsi-common-react/src/models/nominals";
import { Link } from "tsi-common-react/src/common/Link";
import { getPageSetting } from "tsi-common-react/src/utils/settings";
import {
    // TODO in 2019 Q2 this guide: Don't import things across applications
    buildRetailModalPreapprovalTrigger,
    buildRetailModalFinancingTrigger,
} from "../../Checkout/index";
import { NearbyStoresProduct } from "../../RetailLocator/containers/NearbyStoresProduct";
import { NearbyFlagshipStores } from "../../RetailLocator/containers/NearbyFlagshipStores";
import { PDPModalLocationSearch } from "../../RetailLocator/containers/PDPModalLocationSearch";
import { focusElement } from "tsi-common-react/src/utils/keyboardFocus";
import { urls } from "tsi-common-react/src/utils/urls";

import "./RetailModal.scss";
import iconXClose from "../../svg/x-close.svg";
import pdpModalEmptyState from "../../img/pdp/pdp-modal-empty-state.png";

interface IProps {
    productTitle: string;
    productUUID: IProductUUID;
    defaultOpen?: boolean;
    showPrequal: boolean;
}

interface IState {
    modalIsOpen: boolean;
    emptyStage: boolean;
}

export class RetailModal extends React.Component<IProps, IState> {
    public state: IState = {
        modalIsOpen: !!this.props.defaultOpen,
        emptyStage: false,
    };

    private readonly onOpenModal = (event?: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            modalIsOpen: true,
        });
    };

    private readonly onCloseModal = (
        event?: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            modalIsOpen: false,
        });
    };

    private displayEmptyState(state: boolean) {
        if (this.state.emptyStage !== state) {
            this.setState({
                emptyStage: state,
            });
        }
    }

    render() {
        const modalStyle = {
            content: {
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                margin: "auto",
                boxShadow: "none",
            },
        };
        const modalContentClass = classNames({
            "pdp-modal__content": true,
            "pdp-modal__content--empty-state": !!this.state.emptyStage,
        });
        return (
            <>
                <button
                    className="pdp-modal__trigger-link al-pdp-modal__trigger-link button--ghost"
                    onClick={this.onOpenModal}
                >
                    {gettext("More Ways to Buy")}
                </button>
                <Modal
                    aria={{ modal: true }}
                    contentLabel={gettext("Visit a Retail Store")}
                    className="pdp-modal"
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={() => {
                        focusElement(".pdp-modal__close button--ghost");
                    }}
                    onRequestClose={this.onCloseModal}
                    role="dialog"
                    style={modalStyle}
                >
                    <button
                        aria-label={gettext("Close Modal")}
                        className="pdp-modal__close button--ghost"
                        onClick={this.onCloseModal}
                    >
                        <SVG
                            aria-hidden="true"
                            className="pdp-modal__close--icon"
                            src={iconXClose}
                            title={gettext("Close Icon")}
                        />
                    </button>
                    <div className="pdp-modal__buy-direct">
                        <div className="pdp-modal__header pdp-modal__header--narrow">
                            <div className="pdp-modal__header-wrapper u-flex-container">
                                <h2 className="pdp-modal__title">
                                    {gettext("Buy Direct From Us")}
                                </h2>
                            </div>
                        </div>
                        <div className="pdp-modal-cta">
                            <div className="pdp-modal-cta__item pdp-modal__block">
                                <h5 className="pdp-modal__subtitle">
                                    {gettext("Buy Now Online")}
                                </h5>
                                <Link
                                    href={urls.pageURL("mattresses-link")}
                                    className="pdp-modal__link pdp-modal-cta__link al-more-ways-to-buy-close_modal"
                                >
                                    {gettext("View Mattresses")}
                                </Link>
                            </div>
                            <div className="pdp-modal-cta__item pdp-modal__block">
                                <h5 className="pdp-modal__subtitle">
                                    {gettext("Call a Sleep Expert")}
                                </h5>
                                <a
                                    href={getPageSetting(
                                        "pre-order-phone-number-link",
                                    )}
                                    className="pdp-modal__link pdp-modal-cta__link al-more-ways-to-buy-phone"
                                >
                                    {getPageSetting(
                                        "pre-order-phone-number-display",
                                    )}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="pdp-modal__locator-content">
                        <div className="pdp-modal__header pdp-modal__header--search">
                            <div className="pdp-modal__header-wrapper u-flex-container">
                                <h1 className="pdp-modal__title">
                                    <div className="pdp-modal__title-wrapper u-flex-container">
                                        {gettext(
                                            "Visit your Tempur-Pedic before you buy",
                                        )}
                                    </div>
                                </h1>
                                <div className="pdp-modal__search">
                                    <PDPModalLocationSearch classNames="pdp-modal__input pdp-modal-locator__input al-pdp-modal__locator-input al-more-ways-to-buy-zip" />
                                </div>
                            </div>
                        </div>
                        {!this.state.emptyStage && (
                            <div className="pdp-modal-available">
                                {interpolate(
                                    gettext(
                                        "Your %s is on display at the following locations.",
                                    ),
                                    [this.props.productTitle],
                                )}
                            </div>
                        )}
                        <div className={modalContentClass}>
                            <NearbyFlagshipStores
                                productTitle={this.props.productTitle}
                                displayEmptyState={(state) => {
                                    this.displayEmptyState(state);
                                }}
                            />
                        </div>
                        <div className="pdp-modal-locator">
                            {!this.state.emptyStage ? (
                                <>
                                    <h5 className="pdp-modal__subtitle pdp-modal__subtitle--alt">
                                        {gettext("Shop Local Retailers")}
                                    </h5>
                                    <div className="pdp-modal__header pdp-modal__header--narrow">
                                        <div className="pdp-modal__title">
                                            {gettext("Local Retailers")}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <img
                                    className="pdp-modal__empty-state"
                                    aria-hidden="true"
                                    alt="Empty state"
                                    src={pdpModalEmptyState}
                                />
                            )}
                            <NearbyStoresProduct
                                product={this.props.productUUID}
                            />
                        </div>
                    </div>
                    <div className="pdp-modal-adspace">
                        {this.props.showPrequal
                            ? buildRetailModalPreapprovalTrigger(
                                  this.onCloseModal,
                              )
                            : buildRetailModalFinancingTrigger()}
                    </div>
                </Modal>
            </>
        );
    }
}
