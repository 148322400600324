import React, { useState, useEffect, useMemo } from "react";
import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";
import classNames from "classnames";
import { FinancingModalTrigger } from "tsi-common-react/src/apps/financing/FinancingModalTrigger";
import { useAppSelector } from "tsi-common-react/src/apps/reducers";
import { urls } from "tsi-common-react/src/utils/urls";
import { Link } from "tsi-common-react/src/common/Link";
import {
    getDefaultFinancingPlan,
    getLongestActivePlan,
    FinancingPlanMeta,
} from "tsi-common-react/src/utils/financing";
import { formatFinancingPlan } from "tsi-common-react/src/utils/format";

interface IProps {
    applicationSource?: string;
    cardImage?: string;
}

const checkNowClassesDesktop = classNames({
    "button": true,
    "pre-approval-ad__button": true,
    "pre-approval-ad__button--block": true,
    "pre-approval-ad__button--check": true,
    "al-homepage__pre-approval-ad--check-now": true,
    "al-homepage__pre-approval-ad--check-now-desktop": true,
});
const prequalifyClassesMobile = classNames({
    "button": true,
    "pre-approval-ad__button": true,
    "pre-approval-ad__button--block": true,
    "pre-approval-ad__button--check": true,
    "al-homepage__pre-approval-ad--pre-qualify": true,
    "al-homepage__pre-approval-ad--pre-qualify-mobile": true,
});
const applyNowClassesMobile = classNames({
    "pre-approval-ad__apply-alternate-link": true,
    "al-homepage__pre-approval-ad--apply-now": true,
    "al-homepage__pre-approval-ad--apply-now-mobile": true,
});
const iconClasses = classNames({
    "pre-approval-ad__icon": true,
    "pre-approval-ad__icon--block": true,
    "pre-approval-ad__icon--card": true,
});

export const FinancingModalTriggerBlock = (props: IProps) => {
    const [plan, setPlan] = useState<FinancingPlanMeta | null>(null);
    const financingPlans = useAppSelector(
        (state) => state.checkout.data.financing_plans,
    );
    const defaultPlan = useMemo(getDefaultFinancingPlan, []);

    useEffect(() => {
        const getPlan = async () => {
            const longestPlan = await getLongestActivePlan(financingPlans);
            setPlan(longestPlan || defaultPlan);
        };
        getPlan();
    }, [plan]);

    const formattedPlan = useMemo(() => formatFinancingPlan(plan), [plan]);
    return (
        <div className="pre-approval-ad__block-container">
            <div className="pre-approval-ad pre-approval-ad--block l-capped-width u-flex-container">
                <img
                    className={iconClasses}
                    alt={gettext("Tempur-Pedic Credit Card")}
                    src={props.cardImage}
                />
                <div className="pre-approval-ad__terms">
                    <div className="pre-approval-ad__terms-line">
                        <p>
                            {interpolate(
                                gettext("%(apr)s APR for %(length)s Months"),
                                formattedPlan,
                            )}
                            <sup>
                                <Link href={urls.pageURL("finance-link")}>
                                    {formattedPlan.superscript}
                                    <span className="ada-screenreader-only">
                                        {gettext(
                                            "Additional information about Terms and Conditions",
                                        )}
                                    </span>
                                </Link>
                            </sup>
                        </p>
                    </div>
                    <div className="pre-approval-ad__see pre-approval-ad__see--block">
                        {gettext(
                            "Check if you pre-qualify without affecting your credit score.",
                        )}
                    </div>
                </div>
                <FinancingModalTrigger
                    modalType="prequal-app"
                    className={checkNowClassesDesktop}
                    applicationSource={props.applicationSource}
                >
                    {gettext("Pre-Qualify")}
                </FinancingModalTrigger>
            </div>
            <div className="block__pre-approval-ad--mobile pre-approval-ad u-flex-container">
                <div className="pre-approval-ad__see pre-approval-ad__see--block-mobile">
                    <div className="pre-approval-ad__see--bold">
                        {gettext("Check if you pre-qualify")}
                    </div>
                    <div className="pre-approval-ad__see--small">
                        {gettext("without affecting your credit score.")}
                    </div>
                </div>
                <FinancingModalTrigger
                    modalType="prequal-app"
                    className={prequalifyClassesMobile}
                    applicationSource={props.applicationSource}
                >
                    {gettext("Check Now")}
                </FinancingModalTrigger>
                <div className="pre-approval-ad__apply-alternate">
                    or
                    <FinancingModalTrigger
                        modalType="full-app"
                        className={applyNowClassesMobile}
                        applicationSource={props.applicationSource}
                    >
                        {gettext("Apply Now")}
                    </FinancingModalTrigger>
                </div>
            </div>
        </div>
    );
};
