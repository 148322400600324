/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { mostSimilarProducts, userMatchPoint } from "../selectors";
import {
    ICustomerMatchPoint,
    IMattressBodyType,
    IMattressOptionConstant,
} from "../models.interfaces";
import {
    IMattressMatchPreferenceSet,
    IMattressMatchResults,
} from "../../../models/catalogue.interfaces";
import { Coolness, getStepNameFromIdx, Step } from "../constants";
import * as constants from "../constants";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { connect } from "react-redux";
import { Loaders } from "../loaders";
import { MattressMatchNav } from "./MattressMatchNav";
import { MattressSelectorSize } from "../components/MattressSize";
import { MattressOption } from "../components/Option";
import { MattressSelectorBodyType } from "../components/MattressBodyType";
import { NextButton } from "../elements/NextButton";

import { MattressMatchResults } from "../components/Results";
import styles from "./MattressMatch.module.scss";

interface IOwnProps {}

interface IReduxProps {
    [key: string]: any;
    userMatchPoint?: ICustomerMatchPoint;
    mattressMatches: IMattressMatchResults | null;
    currentScreen: Step;
    selectedSize: constants.Mattress_Size;
    selectedFirmness: number;
    selectedCoolness: number;
    selectedBudget: number;
    selectedHeight: number;
    selectedWeight: number;
}

interface IDispatchProps {
    loaders: Loaders;
    onSelectHeight: Dispatchers["onSelectHeight"];
    onSelectOption: Dispatchers["onSelectOption"];
    onSelectWeight: Dispatchers["onSelectWeight"];
    onSelectSize: Dispatchers["onSelectSize"];
    onNavigate: Dispatchers["onNavigate"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

export class MattressMatchContainer extends React.Component<IProps> {
    private readonly onNavigate = (step: Step) => {
        this.props.onNavigate(step);
    };

    private readonly onSelectHeight = (height: number) => {
        this.props.onSelectHeight(height);
    };
    private readonly onSelectWeight = (weight: number) => {
        this.props.onSelectWeight(weight);
    };

    private readonly onSelectOption = (selectedValue: number, type: Step) => {
        this.props.onSelectOption(selectedValue, type);
    };
    private readonly onSelectSize = (size: constants.Mattress_Size) => {
        this.props.onSelectSize(size);
        this.onNavigate(Step.FEEL);
    };
    private readonly getBodyTypeValue = (
        options: IMattressBodyType[],
        level: number,
    ): string => {
        const option = options.find((opt) => opt.level === level);
        return option ? option.value : "";
    };
    private readonly getOptionValue = (
        options: IMattressOptionConstant[],
        level: number,
    ): string => {
        const option = options.find((opt) => opt.level === level);
        return option ? option.value : "";
    };

    private readonly loadResults = () => {
        const cooling = this.getOptionValue(
            constants.COOLNESS,
            this.props.selectedCoolness,
        );
        const data: IMattressMatchPreferenceSet = {
            preferred_feel: this.getOptionValue(
                constants.FIRMNESS,
                this.props.selectedFirmness,
            ),
            height: this.getBodyTypeValue(
                constants.HEIGHT,
                this.props.selectedHeight,
            ),
            weight: this.getBodyTypeValue(
                constants.WEIGHT,
                this.props.selectedWeight,
            ),
            cooling: cooling ? cooling !== Coolness.NEVER : false, // If the received value indicates sometimes or always sleep hot, those inputs will be weighted the same and be accepted as true for cooling while matching conditions.
            price_level: this.getOptionValue(
                constants.BUDGET,
                this.props.selectedBudget,
            ),
        };
        this.props.loaders.loadResults(data);
    };
    private buildScreen(step: Step) {
        const options = getStepNameFromIdx(step).toUpperCase();

        switch (step) {
            case Step.SIZE:
                return (
                    <MattressSelectorSize onSelectSize={this.onSelectSize} />
                );
            case Step.FEEL:
            case Step.COOL:
            case Step.BUDGET:
                return (
                    <MattressOption
                        onSelectOption={this.onSelectOption}
                        selectedOption={
                            this.props[`selected${getStepNameFromIdx(step)}`]
                        }
                        selectedFirmness={this.props.selectedFirmness}
                        selectedCoolness={this.props.selectedCoolness}
                        selectedBudget={this.props.selectedBudget}
                        options={(constants as any)[options]}
                        stepIdx={step}
                        key={step}
                    />
                );
            case Step.BODY_TYPE:
                return (
                    <MattressSelectorBodyType
                        onSelectHeight={this.onSelectHeight}
                        onSelectWeight={this.onSelectWeight}
                        selectedHeight={this.props.selectedHeight}
                        selectedWeight={this.props.selectedWeight}
                    />
                );
            case Step.RESULTS:
                return <MattressMatchResults {...this.props} />;
        }
        return;
    }

    render() {
        const navProps = {
            selectedSize: this.props.selectedSize,
            selectedHeight: this.props.selectedHeight,
            selectedWeight: this.props.selectedWeight,
            selectedFirmness: this.props.selectedFirmness,
            selectedCoolness: this.props.selectedCoolness,
            selectedBudget: this.props.selectedBudget,
            currentScreen: this.props.currentScreen,
            onNavigate: this.props.onNavigate,
        };

        const buttonProps = {
            onNavigate: this.props.onNavigate,
            currentScreen: this.props.currentScreen,
            selectedHeight: this.props.selectedHeight,
            selectedWeight: this.props.selectedWeight,
            selectedFirmness: this.props.selectedFirmness,
            selectedBudget: this.props.selectedBudget,
            selectedCoolness: this.props.selectedCoolness,
            loadResults: this.loadResults,
        };
        return (
            <>
                <div
                    className={styles.container}
                    data-screen={this.props.currentScreen}
                >
                    {this.buildScreen(this.props.currentScreen)}
                    {this.props.currentScreen !== Step.RESULTS && (
                        <NextButton {...buttonProps} />
                    )}
                </div>
                {this.props.currentScreen > Step.SIZE && (
                    <MattressMatchNav {...navProps} />
                )}
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"mattressmatch", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.mattressmatch || defaults;
    return {
        userMatchPoint: userMatchPoint(state),
        mattressMatches: mostSimilarProducts(state),
        currentScreen: state.ui.currentScreen,
        selectedSize: state.ui.selectedSize,
        selectedFirmness: state.ui.selectedFirmness,
        selectedBudget: state.ui.selectedBudget,
        selectedCoolness: state.ui.selectedCoolness,
        selectedHeight: state.ui.selectedHeight,
        selectedWeight: state.ui.selectedWeight,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    return {
        loaders: loaders,
        onSelectHeight: dispatchers.onSelectHeight,
        onSelectWeight: dispatchers.onSelectWeight,
        onSelectOption: dispatchers.onSelectOption,
        onSelectSize: dispatchers.onSelectSize,
        onNavigate: dispatchers.onNavigate,
    };
};

export const MattressMatch = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MattressMatchContainer);
