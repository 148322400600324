import React from "react";

import { ICustomerMatchPoint } from "../models.interfaces";
import {
    IMattressMatchResult,
    IMattressMatchResults,
} from "../../../models/catalogue.interfaces";
import { Mattress_Size } from "../constants";

import styles from "./Results.module.scss";
import { Loaders } from "../loaders";
import { Result } from "./Result";
import LoadingSpinner from "../../../common/LoadingSpinner";

interface IOwnProps {
    loaders: Loaders;
    userMatchPoint?: ICustomerMatchPoint;
    selectedSize: Mattress_Size;
    selectedFirmness: number;
    selectedHeight: number;
    selectedWeight: number;
    selectedCoolness: number;
    selectedBudget: number;
    mattressMatches: IMattressMatchResults | null;
}

interface IState {}

interface IProps extends IOwnProps {}

export class MattressMatchResults extends React.Component<IProps, IState> {
    render() {
        if (!this.props.mattressMatches) {
            return (
                <div className={styles.loading}>
                    <LoadingSpinner />
                </div>
            );
        }
        return (
            <>
                <div className={styles.results}>
                    {this.props.mattressMatches.map(
                        (mattressMatchResult: IMattressMatchResult, idx) => {
                            return (
                                <Result
                                    key={idx}
                                    product={mattressMatchResult.product}
                                    selectedBudget={this.props.selectedBudget}
                                    selectedCoolness={
                                        this.props.selectedCoolness
                                    }
                                    selectedFirmness={
                                        this.props.selectedFirmness
                                    }
                                    selectedSize={this.props.selectedSize}
                                    productImg={mattressMatchResult.image_url}
                                    productCopy={mattressMatchResult.copy}
                                />
                            );
                        },
                    )}
                </div>
            </>
        );
    }
}
