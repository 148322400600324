import React from "react";
import { useAppSelector } from "tsi-common-react/src/apps/reducers";
import SVG from "react-inlinesvg";
import { strToBool } from "tsi-common-react/src/utils/format";
import { RichText } from "tsi-common-react/src/common/RichText";
import { ChatLink } from "tsi-common-react/src/apps/chat/ChatLink";
import { getPageSetting } from "tsi-common-react/src/utils/settings";
import { urls } from "tsi-common-react/src/utils/urls";
import iconSpeechBubblesTalk from "../../svg/speech-bubbles-talk.svg";

interface IProps {}

export const CheckoutSidebarContent = (_props: IProps) => {
    const basketId = useAppSelector(
        (state) => state.checkout.data.basket?.encoded_basket_id,
    );
    let questionsBlock: JSX.Element | null = null;

    if (strToBool(getPageSetting("enable-checkout-sidebar-questions-block"))) {
        questionsBlock = (
            <div className="basket-summary__help">
                <h3>
                    <SVG
                        className="basket-summary__help-icon"
                        src={iconSpeechBubblesTalk}
                        title={gettext("Speech Bubbles Icon")}
                    />
                    {gettext("Questions?")}
                </h3>
                <div className="basket-summary__text">
                    <ChatLink
                        className="basket-summary__help--link"
                        chatOfflineLink={urls.pageURL("contact-link")}
                    />
                    <span>
                        {" "}
                        or{" "}
                        <strong>
                            Call{" "}
                            {getPageSetting("pre-order-phone-number-display")}
                        </strong>{" "}
                        and reference <strong>{basketId}</strong>
                    </span>
                    <br />
                </div>
            </div>
        );
    }
    return (
        <div className="basket-summary__sidebar-content">
            {questionsBlock}
            <RichText html={getPageSetting("checkout-sidebar-extra-content")} />
        </div>
    );
};
