import React from "react";

import { ICustomerMatchPoint } from "../models.interfaces";
import { IProduct } from "../../../models/catalogue.interfaces";

import { ConfiguratorFinancing } from "../../../common/ConfiguratorFinancing";
import { ConfiguratorProductPrice } from "../../../common/ConfiguratorProductPrice";
import { Image } from "../../../common/Image";
import { Link } from "../../../common/Link";
import { Mattress_Size } from "../constants";
import { Rating } from "../elements/Rating";
import { DiscountDisplayMode } from "../../configurator/constants";
import { ValueProps } from "../components/ValueProps";
import styles from "./Result.module.scss";
import RichText from "../../../common/RichText";
import { addProductToBasket } from "../../../apps/configurator/actions";
import { trackAddToBasketEvent } from "../../../utils/analytics";
import urls from "../../../utils/urls";

interface IOwnProps {
    calculatedFirmness?: number;
    userMatchPoint?: ICustomerMatchPoint;
    selectedSize: Mattress_Size;
    selectedBudget: number;
    selectedCoolness: number;
    selectedFirmness: number;
    product: IProduct;
    productImg: string;
    productCopy: string;
}

interface IProps extends IOwnProps {}
interface IState {}

export class Result extends React.Component<IProps, IState> {
    private readonly onAddToBasket = async (
        event: React.MouseEvent<HTMLElement>,
        product: IProduct,
    ) => {
        event.preventDefault();
        try {
            await addProductToBasket(product.url);
            trackAddToBasketEvent(product);
            urls.navigateTo("basket-summary");
        } catch (e) {
            return;
        }
    };

    render() {
        const product = this.props.product;
        const attrs = product?.attributes;

        return (
            <div className={styles.result}>
                <div className={styles.header}>
                    <span className={styles.productTitle}>
                        {product.parent?.title}
                    </span>
                    <p className={styles.productFeel}>
                        {attrs.option_feel?.value}
                    </p>
                    {product?.rating && (
                        <div className={styles.productRating}>
                            <Rating rating={product.rating} />
                        </div>
                    )}
                </div>
                <div className={styles.productInfo}>
                    <div className={styles.imageContainer}>
                        <Image
                            data-src={this.props.productImg}
                            className={styles.productImage}
                            alt=""
                            lazy={true}
                        />
                    </div>
                    <div className={styles.priceWrapper}>
                        <ConfiguratorProductPrice
                            price={product?.price}
                            strikeThroughMSRP={false}
                            discountDisplayMode={
                                DiscountDisplayMode.SHOW_ABSOLUTE
                            }
                            actualPriceStyle={"match"}
                        ></ConfiguratorProductPrice>
                        <ConfiguratorFinancing
                            price={product?.price}
                            variant={product}
                        ></ConfiguratorFinancing>
                    </div>
                    <Link
                        className={styles.detailsButton}
                        target="_top"
                        href={product.link}
                    >
                        Product Details
                    </Link>
                    <button
                        className={styles.addButton}
                        onClick={(e) => {
                            this.onAddToBasket(e, product);
                        }}
                    >
                        Add to Cart
                    </button>
                    {product.value_props && <ValueProps />}
                </div>
                <div className={styles.optionContainer}>
                    <h1>Why you&apos;ll love it.</h1>
                    <ul>
                        <RichText html={this.props.productCopy} />
                    </ul>
                </div>
            </div>
        );
    }
}
