import * as productsAPI from "../../api/products";
import { markPerfTiming } from "../../utils/performance";
import { Dispatchers } from "./dispatchers";
import { IMattressMatchPreferenceSet } from "../../models/catalogue.interfaces";

export class Loaders {
    dispatchers: Dispatchers;

    constructor(dispatchers: Dispatchers) {
        this.dispatchers = dispatchers;
    }

    public readonly loadResults = async (data: IMattressMatchPreferenceSet) => {
        markPerfTiming("mattress-match", "loadResults:start");
        const results = await productsAPI.submitMattressMatchPreferences(data);
        this.dispatchers.loadedResults(results);
        markPerfTiming("mattress-match", "loadResults:done");
    };
}
