import React from "react";
import Modal from "react-modal";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { urls } from "tsi-common-react/src/utils/urls";
import { PreQualCustomerResponse } from "tsi-common-react/src/models/financing";
import { IPreScreenModalProps } from "tsi-common-react/src/apps/checkout/checkout-steps/BillingAddress";
import { FinancingModalTrigger } from "tsi-common-react/src/apps/financing/FinancingModalTrigger";
import { getPageSetting } from "tsi-common-react/src/utils/settings";
import { Trans } from "tsi-common-react/src/common/Trans";
import { Link } from "tsi-common-react/src/common/Link";
import { focusElement } from "tsi-common-react/src/utils/keyboardFocus";
import tempurPreApprovalCouple from "../../img/checkout/tempur-pre-approval-couple.jpg";
import iconXClose from "../../svg/x-close.svg";
import tempurCreditCard from "../../img/checkout/tempur-credit-card.png";
import iconNoAnnualFee from "../../svg/no-annual-fee.svg";
import iconInstantUse from "../../svg/instant-use.svg";

interface IProps extends IPreScreenModalProps {}

interface IState {}

export class PreScreenModal extends React.Component<IProps, IState> {
    private readonly onClose = (
        e: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
        e.preventDefault();
        this.props.onRequestClose(PreQualCustomerResponse.CLOSE);
    };

    private readonly onAccept = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        this.props.onRequestClose(PreQualCustomerResponse.ACCEPT);
    };

    private readonly onReject = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        this.props.onRequestClose(PreQualCustomerResponse.REJECT);
    };

    render() {
        const modalStyle = {
            content: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                boxShadow: "none",
            },
        };
        const plansHTML = Array.from(
            document.querySelectorAll<HTMLElement>(
                ".financing-plan-table__plan",
            ),
        );
        const bestPlan = plansHTML[plansHTML.length - 1];
        let planAPR = 0;
        let planLength = 0;
        if (bestPlan) {
            planAPR = parseInt(bestPlan.dataset.apr || "", 10);
            planLength = parseInt(bestPlan.dataset.length || "", 10);
        }
        const bgImgStyle: React.CSSProperties = {
            backgroundImage: `url('${tempurPreApprovalCouple}')`,
        };
        const continueClass = classNames({
            "button": true,
            "checkout-step-pre-approval__button": true,
            "checkout-step-pre-approval__button--continue": true,
            "al-checkout-pre-approval__button--continue": true,
        });
        const rejectClass = classNames({
            "button": true,
            "button--inverse": true,
            "checkout-step-pre-approval__button": true,
            "checkout-step-pre-approval__button--cancel": true,
            "al-checkout-pre-approval__button--cancel": true,
        });
        return (
            <Modal
                aria={{ modal: true }}
                contentLabel={gettext("Pre-Approval")}
                style={modalStyle}
                className="checkout-step-pre-approval"
                isOpen={this.props.isOpen}
                onAfterOpen={() => {
                    focusElement(".checkout-step-pre-approval__close");
                }}
                onRequestClose={this.onClose}
                role="dialog"
            >
                <div className="checkout-step-pre-approval__header">
                    <button
                        aria-label={gettext("Close")}
                        className="checkout-step-pre-approval__close al-checkout-pre-approval__button--close"
                        onClick={this.onClose}
                    >
                        <SVG
                            aria-hidden="true"
                            src={iconXClose}
                            title={gettext("Close Icon")}
                        />
                    </button>
                    <h1 className="checkout-step-pre-approval__header-title">
                        <Trans
                            fmtString={gettext(
                                "The Tempur-Pedic<Superscript>®</Superscript> Credit Card",
                            )}
                            data={{
                                Superscript: (content) => (
                                    <span
                                        key="1"
                                        className="checkout-step-pre-approval__header-title--r"
                                    >
                                        <sup>{content}</sup>
                                    </span>
                                ),
                            }}
                        />
                    </h1>
                </div>
                <div className="checkout-step-pre-approval__body">
                    <div className="checkout-step-pre-approval__title-container">
                        <div className="checkout-step-pre-approval__title-image-container">
                            <img
                                src={tempurCreditCard}
                                className="checkout-step-pre-approval__title-image"
                            />
                        </div>
                        <h2 className="checkout-step-pre-approval__title">
                            You&apos;re{" "}
                            <span className="checkout-step-pre-approval__title--bold">
                                pre-qualified<sup>*</sup>
                            </span>{" "}
                            <div className="checkout-step-pre-approval__pre-title">
                                for a Tempur-Pedic<sup>®</sup> Credit Card
                                account.
                            </div>
                        </h2>
                    </div>
                    <div className="checkout-step-pre-approval__content checkout-step-pre-approval__content--left">
                        <div className="checkout-step-pre-approval__data-box checkout-step-pre-approval__data-box--limit">
                            <div className="checkout-step-pre-approval__data-box-title">
                                pre-qualified<sup>*</sup> credit limit up to
                            </div>
                            <div className="checkout-step-pre-approval__data-box-data">
                                ${this.props.preApprovalLimit}
                            </div>
                        </div>
                        <div className="checkout-step-pre-approval__data-box checkout-step-pre-approval__data-box--offer">
                            <div className="checkout-step-pre-approval__data-box-title">
                                {gettext("special financing offer")}
                            </div>
                            <div className="checkout-step-pre-approval__data-box-data">
                                {interpolate(
                                    gettext(
                                        "%(apr)s% APR for %(length)s months",
                                    ),
                                    {
                                        apr: `${planAPR}`,
                                        length: `${planLength}`,
                                    },
                                    true,
                                )}
                                <sup>
                                    <Link href={urls.pageURL("finance-link")}>
                                        1
                                    </Link>
                                </sup>
                            </div>
                        </div>
                        <div className="checkout-step-pre-approval__value-props">
                            <div className="checkout-step-pre-approval__value-prop">
                                <SVG
                                    className="checkout-step-pre-approval__value-icon checkout-step-pre-approval__value-icon--fee"
                                    title={gettext("Pre-Approved Icon")}
                                    src={iconNoAnnualFee}
                                />
                                {gettext("No Annual Fee")}
                                <sup>1</sup>
                            </div>
                            <div className="checkout-step-pre-approval__value-prop">
                                <SVG
                                    className="checkout-step-pre-approval__value-icon checkout-step-pre-approval__value-icon--instant"
                                    title={gettext("Use Now Icon")}
                                    src={iconInstantUse}
                                />
                                {gettext("Use instantly on Tempurpedic.com")}
                            </div>
                        </div>
                        <div className="checkout-step-pre-approval__opt-out">
                            {gettext(
                                'You can choose to stop receiving "prescreened" offers of credit from this and other companies by calling toll-free 1-888-567-8688. See the PRESCREEN & OPT-OUT NOTICE by selecting "Continue to Application" for more information about prescreened offers.',
                            )}
                        </div>
                    </div>
                    <div className="checkout-step-pre-approval__content checkout-step-pre-approval__content--right">
                        <div
                            className="checkout-step-pre-approval__image-container"
                            style={bgImgStyle}
                        ></div>
                        <div className="checkout-step-pre-approval__buttons">
                            <FinancingModalTrigger
                                modalType="full-app"
                                className={continueClass}
                                onClick={this.onAccept}
                            >
                                {gettext("Continue to Application")}
                            </FinancingModalTrigger>
                            <button
                                className={rejectClass}
                                onClick={this.onReject}
                            >
                                {gettext("Not now")}
                            </button>
                        </div>
                    </div>
                    <div
                        className="checkout-step-pre-approval__small-print"
                        dangerouslySetInnerHTML={{
                            __html: getPageSetting(
                                "pre-approval-modal-fine-print",
                            ),
                        }}
                    ></div>
                </div>
            </Modal>
        );
    }
}
