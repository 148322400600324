import React from "react";
import { connect } from "react-redux";
import { ILocation } from "tsi-common-react/src/models/location.interfaces";
import { IMiles, isoMiles } from "tsi-common-react/src/models/nominals";
import { IStoreSearchFilters } from "tsi-common-react/src/apps/retail/models.interfaces";
import { LocationInput } from "tsi-common-react/src/apps/common/containers/LocationInput";
import { IFilterChangeHandler } from "../models.interfaces";
import { LocatorFilterPopover } from "./LocatorFilters_Popover";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { storeFilterSelector } from "tsi-common-react/src/apps/retail/selectors";

interface IOwnProps {
    filterValues: IStoreSearchFilters;
    currentLocation: ILocation | null;
    onChange: IFilterChangeHandler;
}

interface IReduxProps {
    storeFilters: IStoreSearchFilters;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {
    distanceValue: IMiles;
}

export class LocatorFilterContainer extends React.Component<IProps, IState> {
    state: IState = {
        distanceValue: this.props.filterValues.distance,
    };

    private readonly distanceOptions = [10, 20, 30, 40, 50, 75, 100];
    private readonly filterElem = React.createRef<LocatorFilterPopover>();

    componentDidUpdate(prevProps: IReduxProps) {
        // Change input value if miles selection is triggered from the other dropdown
        const prev = prevProps.storeFilters.distance;
        const next = this.props.storeFilters.distance;
        if (next !== prev) {
            this.setState({
                distanceValue: next,
            });
        }
    }

    private readonly onDistanceChange = (
        event: React.FormEvent<HTMLSelectElement>,
    ) => {
        const newDistance = isoMiles.wrap(
            parseInt(event.currentTarget.value, 10),
        );
        this.setState({
            distanceValue: newDistance,
        });
        this.props.onChange({
            distance: newDistance,
        });
    };

    private readonly onAfterLocationChange = () => {
        if (this.filterElem.current) {
            this.filterElem.current.onRequestClose();
        }
    };

    private readonly onSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();
    };

    render() {
        const labelText =
            this.props.currentLocation?.formatted_address_long ||
            this.props.currentLocation?.formatted_address ||
            "you";
        const locationInputID = "location-autocomplete";
        return (
            <LocatorFilterPopover
                ref={this.filterElem}
                id="location"
                labelText={labelText}
            >
                <form
                    className="filters__dropdown filters__dropdown--location"
                    onSubmit={this.onSubmit}
                    role="dialog"
                    aria-modal={true}
                >
                    <fieldset>
                        <legend className="filters__instruct">
                            Change your location to find retailers in that area.
                        </legend>
                        <div className="form__field filters__inputs">
                            <div className="form__input form__input--zip">
                                <label
                                    htmlFor={locationInputID}
                                    className="ada-screenreader-only"
                                >
                                    {gettext("Location")}
                                </label>
                                <LocationInput
                                    inputProps={{
                                        id: locationInputID,
                                        name: "bh-sl-address",
                                        placeholder:
                                            "Enter address, city, state or zip",
                                        title: "Enter address, city, state or zip",
                                    }}
                                    inputFormat="long"
                                    onAfterChange={this.onAfterLocationChange}
                                />
                            </div>
                        </div>
                        <div className="form__field filters__inputs">
                            <div className="form__input form__input--distance">
                                <select
                                    className="form__select"
                                    name="bh-sl-maxdistance"
                                    aria-label="Max Distance"
                                    onChange={this.onDistanceChange}
                                    value={isoMiles.unwrap(
                                        this.state.distanceValue,
                                    )}
                                >
                                    {this.distanceOptions.map((opt) => {
                                        return (
                                            <option key={opt} value={opt}>
                                                {opt} miles
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="filters__actions sl-search__actions al-location--submit-zip">
                            <input
                                className="button filters__button"
                                type="submit"
                                aria-label="Search"
                            />
                        </div>
                    </fieldset>
                </form>
            </LocatorFilterPopover>
        );
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        storeFilters: storeFilterSelector(rootState),
        ...ownProps,
    };
};

export const LocationFilter = connect(mapStateToProps)(LocatorFilterContainer);
