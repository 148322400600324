/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Step, getStepNameFromIdx } from "../constants";
import classNames from "classnames";
import styles from "./NextButton.module.scss";

interface IProps {
    [key: string]: any;
    onNavigate: (step: Step) => void;
    currentScreen: Step;
    selectedHeight: number | null;
    selectedWeight: number | null;
    loadResults: () => void;
}
export class NextButton extends React.Component<IProps> {
    private readonly navigateTo = (nextScreen: number) => {
        if (this.props.currentScreen + 1 === Step.RESULTS) {
            this.props.loadResults();
        }
        this.props.onNavigate(nextScreen);
    };
    private isDisabled() {
        switch (this.props.currentScreen) {
            case Step.SIZE:
                return false;
            case Step.BODY_TYPE:
                return (
                    this.props.selectedHeight === 0 ||
                    this.props.selectedWeight === 0
                );
            default:
                return (
                    this.props[
                        `selected${getStepNameFromIdx(
                            this.props.currentScreen,
                        )}`
                    ] === 0
                );
        }
    }
    render() {
        const nextScreen = this.props.currentScreen + 1;
        const disabled = this.isDisabled();
        const buttonClasses = classNames({
            [styles.button]: true,
            [styles.rowAdjust]: this.props.currentScreen === 0,
        });
        return (
            <button
                className={buttonClasses}
                disabled={disabled}
                onClick={() => {
                    this.navigateTo(nextScreen);
                }}
            >
                Next
            </button>
        );
    }
}
