import React from "react";
import { Provider } from "react-redux";
import {
    IProductID,
    isoProductID,
    IImageURL,
    isoImageURL,
} from "tsi-common-react/src/models/nominals";
import {
    IProduct,
    IOptionCode,
} from "tsi-common-react/src/models/catalogue.interfaces";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { check } from "tsi-common-react/src/models/utils";
import { urls } from "tsi-common-react/src/utils/urls";
import { readyStateComplete } from "tsi-common-react/src/utils/events";
import { store, rehydratingStore } from "../store";
import * as pdpModels from "./models";
import * as streamfieldModels from "tsi-common-react/src/models/streamfield-blocks";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

/**
 * Load CMS data and the initially selected product variant
 */
let cmsData: pdpModels.IProductPage | null = null;
let pdpHeroImage: IImageURL | null = null;
let pdpMobileHeroImage: IImageURL | null = null;
let pdpHeroAlt: string | null = null;
let pdpVariantHeroImages: pdpModels.IProductPage["variant_hero_images_with_urls"] =
    [];
let pdpRootProductDescriptions: pdpModels.IProductPage["variant_configurator_description_data"] =
    [];
const detailContainerElem = document.querySelector<HTMLDivElement>(
    "#product-detail-container[data-cms]",
);
if (detailContainerElem) {
    // Load PDP CMS data
    cmsData = check(
        pdpModels.ProductPage.decode(
            JSON.parse(detailContainerElem.dataset.cms || ""),
        ),
    );
    if (cmsData) {
        pdpHeroImage = cmsData.hero_image_url;
        pdpMobileHeroImage = cmsData.mobile_hero_image_url;
        pdpHeroAlt = cmsData.hero_image_alt;
        pdpVariantHeroImages = cmsData.variant_hero_images_with_urls;
        pdpRootProductDescriptions =
            cmsData.variant_configurator_description_data;
    }
}

// Add attribute to fix inconsitent anchoring to PDP reviews
const reviewsElem = "reviews__body";
const hash = urls.getHash(reviewsElem);
if (hash) {
    onDOMContentLoaded.on(async () => {
        document.body.setAttribute("scrollToReviews", hash.toString());
    });
}
/**
 * Place PDPHero components
 */
dynamicPlaceComponent('[data-place-react="pdp-hero"]', async () => {
    const { PDPHero } = await import(
        "tsi-common-react/src/apps/configurator/containers/PDPHero"
    );
    return (
        <Provider store={store}>
            <PDPHero
                defaultBackgroundImage={pdpHeroImage}
                defaultMobileBackgroundImage={pdpMobileHeroImage}
                defaultImageTitle={pdpHeroAlt || ""}
                variantHeroInfo={pdpVariantHeroImages}
            />
        </Provider>
    );
});

/**
 * Render Configurators
 */
dynamicPlaceComponent(
    '[data-place-react="modular-configurator"]',
    async (elem) => {
        const { ModularConfigurator } = await import(
            "tsi-common-react/src/apps/configurator/containers/ModularConfigurator"
        );
        const { UpsellModal } = await import("./components/UpsellModal");
        const { RetailModal } = await import("./components/RetailModal");
        const { ChatLink } = await import(
            "tsi-common-react/src/apps/chat/ChatLink"
        );
        const preorderShippingDescriptions = new Map<IProductID, string>(
            pdpRootProductDescriptions
                .filter((d) => !!d.preorder_shipping_description)
                .map((d) => [d.product, d.preorder_shipping_description!]),
        );
        const isAlternativeDesign = elem.dataset.design === "alt";
        const phoneNumber = elem.dataset.phoneNumber;
        // Wait for the document to load, this needs to render multi product options properly
        await readyStateComplete;
        await rehydratingStore;
        return (
            <Provider store={store}>
                <ModularConfigurator
                    optionSetJSON={elem.dataset.configuratorInitData || ""}
                    passthroughProps={{
                        title: cmsData ? cmsData.title : "",
                        preheader: cmsData ? cmsData.preheader : "",
                        subhead: cmsData ? cmsData.subhead : "",
                        description:
                            cmsData && cmsData.description
                                ? cmsData.description
                                : "",
                        descriptions: pdpRootProductDescriptions
                            ? pdpRootProductDescriptions
                            : [],
                        enableHistoryInteraction: true,
                        liveChatHeader: cmsData ? cmsData.live_chat_header : "",
                        chatLinkComponent: !isAlternativeDesign ? null : (
                            <ChatLink
                                className={"button"}
                                chatOnlineText={gettext("Chat")}
                                chatOfflineLink={urls.pageURL("contact-link")}
                            />
                        ),
                        showUpsellModal: () => {
                            return (
                                !!cmsData &&
                                !!cmsData.bundle_modal_serialized &&
                                cmsData.bundle_modal_serialized.length >= 0
                            );
                        },
                        getUpsellModalComponentClass: () => {
                            return UpsellModal;
                        },
                        buttonColor: "primary-congress-blue",
                        buildAdditionalContent: (
                            product: IProduct | null,
                            optionSet,
                        ) => {
                            if (
                                !product ||
                                !cmsData ||
                                !cmsData.show_more_ways ||
                                !product.uuid
                            ) {
                                return null;
                            }
                            return (
                                <RetailModal
                                    productTitle={product.title}
                                    productUUID={product.uuid}
                                    showPrequal={
                                        optionSet.layout.enable_prequal
                                    }
                                />
                            );
                        },
                        showStarRatingsModal: true,
                        configType: "pdp-hero",
                        getDeliveryCopy: (
                            rootProduct: IProduct,
                            isPreorder: boolean,
                        ) => {
                            if (isPreorder) {
                                return (
                                    preorderShippingDescriptions.get(
                                        rootProduct.id,
                                    ) || ""
                                );
                            }
                            return cmsData && cmsData.shipping_description
                                ? cmsData.shipping_description
                                : "";
                        },
                        getDeliveryIcon: () => {
                            return cmsData && cmsData.shipping_icon_url
                                ? cmsData.shipping_icon_url
                                : null;
                        },
                        showShippingLead: !!(
                            cmsData && cmsData.show_shipping_lead
                        ),
                        showFeelScale: true,
                        showPreTitle: false,
                        showVariantCallout: false,
                        learnMoreSelector: cmsData
                            ? `${cmsData.learn_more_target}`
                            : "#pdp-change-variant-block",
                        strikeThroughMSRP: false,
                        actualPriceStyle: "",
                        boxHeaderContent: "",
                        topBadgeContent: "",
                        phoneNumber: phoneNumber,
                        showImagePerUpgradeOption: false,
                        showFinancingModal: !!(
                            cmsData && cmsData.show_financing_modal
                        ),
                    }}
                />
            </Provider>
        );
    },
);

/**
 * Render Sticky Configurator.
 *
 * This “configurator” is really more of a configurator status indicator. It
 * displays only (1) on the experimental PDP, at mobile size, when the page is
 * scroll down past the normal configurator.
 */
dynamicPlaceComponent('[data-place-react="sticky-configurator"]', async () => {
    const { StickyConfigurator } = await import(
        "tsi-common-react/src/apps/configurator/containers/StickyConfigurator"
    );
    const { UpsellModal } = await import("./components/UpsellModal");
    await readyStateComplete;
    return (
        <Provider store={store}>
            <StickyConfigurator
                financingLink={urls.pageURL("finance-link")}
                basketLink={urls.pageURL("basket-summary")}
                configureGiftsLink={urls.pageURL("configure-gifts")}
                showUpsellModal={() => {
                    return (
                        !!cmsData &&
                        !!cmsData.bundle_modal_serialized &&
                        cmsData.bundle_modal_serialized.length >= 0
                    );
                }}
                getUpsellModalComponentClass={() => {
                    return UpsellModal;
                }}
                upsellModal={UpsellModal}
                onEditClick={() => {
                    const element = document.querySelector(
                        ".pdp-hero-alternative .pdp-hero__content",
                    );
                    element?.scrollIntoView({ behavior: "smooth" });
                }}
                strikeThroughMSRP={false}
                actualPriceStyle={""}
                overrideFinancingCopy={""}
                stickyTrigger={document.querySelector<HTMLElement>(
                    ".pdp-hero__content",
                )}
                isPLCVersion={false}
            />
        </Provider>
    );
});

/**
 * Render Galleries
 */
dynamicPlaceComponent('[data-place-react="pdp-gallery"]', async () => {
    const { Gallery } = await import("./containers/PDPGallery");
    await readyStateComplete; // Wait for the document to completely load, then render the Gallery Component.
    return (
        <Provider store={store}>
            <Gallery />
        </Provider>
    );
});

/**
 * Render ReviewsBlock
 */
dynamicPlaceComponent('[data-place-react="reviews-block"]', async (elem) => {
    const { ReviewsBlock } = await import("./containers/ReviewsBlock");
    return (
        <Provider store={store}>
            <ReviewsBlock
                reviewsBlockJSON={elem.dataset.reviews || ""}
                theme={elem.dataset.theme || ""}
            />
        </Provider>
    );
});

/**
 * Render PDP Product Bundles
 */
dynamicPlaceComponent('[data-place-react="pdp-bundles"]', async () => {
    const { OutOfContextBundleCollection } = await import(
        "./containers/PDPBundles"
    );
    return (
        <Provider store={store}>
            <OutOfContextBundleCollection />
        </Provider>
    );
});

/**
 * Render PDPChangeRootProductBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-change-root-product-block"]',
    async () => {
        const { PDPChangeRootProductBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPChangeRootProductBlock"
        );
        return (
            <Provider store={store}>
                <PDPChangeRootProductBlock />
            </Provider>
        );
    },
);

/**
 * Render ChangeVariantOptionBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-change-variant-option-block"]',
    async (elem) => {
        const { PDPChangeVariantOptionBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPChangeVariantOptionBlock"
        );
        const attributeCode = (elem.dataset.attributeCode ||
            "option_feel") as IOptionCode;
        return (
            <Provider store={store}>
                <PDPChangeVariantOptionBlock attributeCode={attributeCode} />
            </Provider>
        );
    },
);

/**
 * Render SelectedRootProductConditionalBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-selected-root-product-conditional-block"]',
    async (elem) => {
        const { PDPSelectedRootProductConditionalBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPSelectedRootProductConditionalBlock"
        );
        const productID = isoProductID.wrap(
            parseInt(elem.dataset.product || "0", 10),
        );
        const htmlContent = elem.innerHTML || "";
        // Show the root element
        elem.style.display = "block";
        return (
            <Provider store={store}>
                <PDPSelectedRootProductConditionalBlock
                    blockProductID={productID}
                    content={htmlContent}
                />
            </Provider>
        );
    },
);

/**
 * Render SelectedVariantConditionalBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-selected-variant-conditional-block"]',
    async (elem) => {
        const { PDPSelectedVariantConditionalBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPSelectedVariantConditionalBlock"
        );
        const attributeCode = (elem.dataset.attributeCode ||
            "option_feel") as IOptionCode;
        const attributeValue = elem.dataset.attributeValue || "";
        const htmlContent = elem.innerHTML || "";
        // Show the root element
        elem.style.display = "block";
        return (
            <Provider store={store}>
                <PDPSelectedVariantConditionalBlock
                    attributeCode={attributeCode}
                    attributeValue={attributeValue}
                    content={htmlContent}
                />
            </Provider>
        );
    },
);

/**
 * Render TabCardsBlock's from the PDP StreamField
 */
dynamicPlaceComponent('[data-place-react="tab-cards-block"]', async (elem) => {
    const { TabCardsBlock } = await import(
        "tsi-common-react/src/blocks/containers/TabCardsBlock"
    );
    const tabCards = check(
        streamfieldModels.TabCardsBlock.decode(
            JSON.parse(elem.dataset.cms || ""),
        ),
    );
    return (
        <Provider store={store}>
            <TabCardsBlock
                tabCards={tabCards}
                theme={tabCards.theme.theme || ""}
            />
        </Provider>
    );
});

/**
 * Render GalleryBlock
 */
dynamicPlaceComponent('[data-place-react="gallery-block"]', async (elem) => {
    const { GalleryBlock } = await import(
        "tsi-common-react/src/apps/gallery/components/GalleryBlock"
    );
    return (
        <Provider store={store}>
            <GalleryBlock
                galleriesJSON={elem.dataset.galleries || "{}"}
                defaultThumbnail={isoImageURL.wrap(
                    elem.dataset.videoThumbnail || "",
                )}
            />
        </Provider>
    );
});

/**
 * Place TabFeatureAttributeBlock components
 */
dynamicPlaceComponent(
    '[data-place-react="tab-feature-attribute-block"]',
    async (elem) => {
        const { TabFeatureAttributeBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/TabFeatureAttributeBlock"
        );
        return (
            <Provider store={store}>
                <TabFeatureAttributeBlock
                    blockJSON={elem.dataset.content || ""}
                />
            </Provider>
        );
    },
);

/**
 * Render compatibility module
 */
dynamicPlaceComponent('[data-place-react="compatibility"]', async (elem) => {
    const { CompatibilityAccordion } = await import(
        "tsi-common-react/src/blocks/components/CompatibilityAccordion"
    );
    const main = JSON.parse(elem.dataset.cms || "");

    return (
        <CompatibilityAccordion
            cms={main}
            contentPanels={main.compatibility_panels.panels}
        />
    );
});

/**
 * Render hotspot block
 */
dynamicPlaceComponent('[data-place-react="hotspot-block"]', async (elem) => {
    const { HotspotBlock } = await import("./components/Hotspot");
    const main = JSON.parse(elem.dataset.cms || "");

    return (
        <Provider store={store}>
            <HotspotBlock hotspotContent={main} />
        </Provider>
    );
});

/**
 * Render EmailCaptureBlock
 */
dynamicPlaceComponent(
    '[data-place-react="email-capture-block"]',
    async (elem) => {
        const { EmailCaptureContent } = await import(
            "tsi-common-react/src/apps/common/containers/EmailCaptureContent"
        );
        const cms_data = JSON.parse(elem.dataset.cms || "null");

        return <EmailCaptureContent content={cms_data} />;
    },
);
