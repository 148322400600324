import React from "react";
import { connect } from "react-redux";
import { LoadingSpinner } from "tsi-common-react/src/common/LoadingSpinner";
import {
    RetailStoreEventType,
    RetailStoreEventCTAType,
} from "tsi-common-react/src/constants";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import { TDispatchMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { preferredLocationSelector } from "tsi-common-react/src/apps/common/selectors";
import { trackRetailStoreEvent } from "tsi-common-react/src/utils/analytics";
import { IReduxState } from "../../reducers.interfaces";
import { ICMSFlagshipStorePage } from "../models.interfaces";
import { getFlagshipStorePage, getStoreByExternalID } from "../loaders";
import { getFlagshipStoreByExternalID } from "../selectors";
import { Dispatchers } from "../dispatchers";
import { FlagshipStoreInfoMap as FlagshipStoreInfoMapComponent } from "../components/FlagshipStoreInfoMap";

interface IReduxProps {
    location: ILocation | null;
    store: IRetailStoreWithDistance | null;
    storePage: ICMSFlagshipStorePage | null;
}

interface IOwnProps {
    storeID: ISyncStoreID;
    mapMountElement: HTMLElement;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

type IProps = IReduxProps & IOwnProps & IDispatchProps;

interface IState {
    isLoading: boolean;
}

// finds nearest flagship store to location
class FlagshipStoreInfoMapContainer extends React.Component<IProps, IState> {
    public state: IState = {
        isLoading: true,
    };

    componentDidMount() {
        this.loadStoreData();
    }

    componentDidUpdate(prevProps: IProps) {
        const locationChanged =
            JSON.stringify(prevProps.location) !==
            JSON.stringify(this.props.location);
        const storeIDChanged = prevProps.storeID !== this.props.storeID;
        if (locationChanged || storeIDChanged) {
            this.loadStoreData();
        }
    }

    private async loadStoreData() {
        if (!this.props.location) {
            return;
        }
        this.setState({
            isLoading: true,
        });
        const stores = await getStoreByExternalID(
            this.props.location,
            this.props.storeID,
        );
        this.props.dispatchers.addStores(stores);
        const storePage = await getFlagshipStorePage(this.props.storeID);
        this.props.dispatchers.addFlagshipStorePages([storePage]);
        this.setState({
            isLoading: false,
        });
    }

    render() {
        if (this.state.isLoading) {
            return <LoadingSpinner />;
        }
        // Stores and CMS store found
        if (!this.props.store || !this.props.storePage) {
            return (
                <span>
                    {gettext(
                        "Sorry! We can't find a nearby Tempur-Pedic store.",
                    )}
                </span>
            );
        }
        // This may not be the optimal approach in React to locate the buttons used for booking appointments,
        // but it appears that these buttons are standardized and generated using the (Aligned) Rich Text block.
        const buttons =
            document.querySelectorAll<HTMLAnchorElement>("a[class='button']");
        if (buttons) {
            buttons.forEach((element) => {
                const linkText = element.textContent?.trim();
                if (linkText && linkText.toLowerCase() === "book appointment") {
                    const linkUrl = element.getAttribute("href");
                    element.addEventListener("click", () => {
                        trackRetailStoreEvent(
                            RetailStoreEventType.TEMPUR_STORE,
                            this.props.store,
                            RetailStoreEventCTAType.APPOINTMENT,
                            linkText,
                            linkUrl,
                        );
                    });
                }
            });
        }

        return (
            <FlagshipStoreInfoMapComponent
                mapMountElement={this.props.mapMountElement}
                cmsProps={this.props.storePage}
                store={this.props.store}
            />
        );
    }
}

const mapStateToProps = (
    state: IReduxState,
    ownProps: IOwnProps,
): IReduxProps & IOwnProps => {
    const store = state.retail.stores.find((s) => {
        return s.external_id === ownProps.storeID;
    });
    const storePage = getFlagshipStoreByExternalID(
        state.retail.flagshipStorePages,
        ownProps.storeID,
    );
    return {
        ...ownProps,
        location: preferredLocationSelector(state),
        store: store || null,
        storePage: storePage || null,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const FlagshipStoreInfoMap = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FlagshipStoreInfoMapContainer);
