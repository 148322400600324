// Setup Sentry
import * as Sentry from "@sentry/browser";
import { constants } from "@reactivated";

const isError = (err: unknown): err is Error => {
    return (err as Error).stack !== undefined;
};
// Temp for #20198: Log all errors on the employees site
const isEmployeesSite = window.location.origin.indexOf("employees") !== -1;
const ignoredErrors = isEmployeesSite
    ? []
    : [
          // Network errors such as going offline or being blocked by a proxy
          "Failed to fetch",
          "Request has been terminated",
          "NotAllowedError",
          "Unsuccessful HTTP response",
          "NetworkError: Failed to execute",
          "Synchronous XHR in page dismissal",
          // Random plugins/extensions
          "top.GLOBALS",
          // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
          "originalCreateNotification",
          "canvas.contentDocument",
          "MyApp_RemoveAllHighlights",
          "http://tt.epicplay.com",
          "Can't find variable: ZiteReader",
          "jigsaw is not defined",
          "ComboSearch is not defined",
          "http://loading.retry.widdit.com/",
          "atomicFindClose",
          // Facebook borked
          "fb_xd_fragment",
          // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
          // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
          "bmi_SafeAddOnload",
          "EBCallBackMessageReceived",
          // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
          "conduitPage",
          // Generic error code from errors outside the security sandbox
          // You can delete this if using raven.js > 1.0, which ignores these automatically.
          "Script error.",
          // Random Analytics Crap
          "undefined is not an object (evaluating 'document.getElementsByClassName(\"pdp-bundle-item__title\")[1].innerHTML')",
          "undefined is not an object (evaluating 'prds.length')",
          "Can't find variable: fbq",
          "fbq is not defined",
          "undefined is not an object (evaluating 'emailPart.split')",
          "emailPart is undefined",
          "pintrk",
          "Unexpected identifier",
          "The play() request was interrupted by a call to pause().",
          "Unspecified error.",
          "gtm",
          "SnapEngage",
          "ChunkLoadError",
          "onetrust",
          "NotSupportedError",
          // IE11 (See https://github.com/getsentry/sentry-javascript/issues/608)
          "Permission denied",
      ];
Sentry.init({
    dsn: document.body.dataset.sentryDsn,
    release: constants.VERSION,
    environment: document.body.dataset.environment,
    // Only send 0.05% of transaction traces to Sentry (to stay within
    // our 250k traces/month limit).
    tracesSampleRate: 0.0005,
    sampleRate: isEmployeesSite ? 1 : 0.1,
    integrations: [Sentry.browserTracingIntegration()],
    denyUrls: [
        // Ignore Google flakiness
        /\/(gtm|ga|analytics)\.js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    ignoreErrors: ignoredErrors,
    beforeSend: (event, hint) => {
        // const message = hint && hint.originalException ? hint.originalException.toString() : '';
        let message = "";
        if (hint && hint.syntheticException) {
            message += `${hint.syntheticException.name}\n${hint.syntheticException.message}\n${hint.syntheticException.stack}\n`;
        }
        if (hint && hint.originalException) {
            const stack = isError(hint.originalException)
                ? hint.originalException.stack
                : "";
            message += `${hint.originalException.toString()}\n${stack}\n`;
        }
        if (!message) {
            return event;
        }
        const ignore = ignoredErrors.reduce((memo, pattern) => {
            return memo || message.indexOf(pattern) !== -1;
        }, false);
        if (ignore) {
            console.log(
                `Event dropped due to being matched by ignoredErrors list: ${message}`,
            );
        }
        return ignore ? null : event;
    },
});
Sentry.getCurrentScope().setTag("environment.type", "browser");
