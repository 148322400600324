import React from "react";

import SVG from "react-inlinesvg";
import classNames from "classnames";
import styles from "./ValueProps.module.scss";
import warrantyIcon from "../../../../img/icons/mattress-selector/10-year-warranty.svg";
import moonIcon from "../../../../img/icons/mattress-selector/moon.svg";
import freeDeliveryIcon from "../../../../img/icons/mattress-selector/free-delivery.svg";

interface IOwnProps {
    stacked?: boolean;
}

interface IState {}

interface IProps extends IOwnProps {}

export class ValueProps extends React.Component<IProps, IState> {
    private getWarrantyVP() {
        return (
            <li>
                <SVG aria-hidden="true" src={warrantyIcon} />
                10-Year Warranty
            </li>
        );
    }
    private getTrialVP() {
        return (
            <li>
                <SVG aria-hidden="true" src={moonIcon} />
                90-Night Trial
            </li>
        );
    }

    private getDeliveryVP() {
        return (
            <li>
                <SVG aria-hidden="true" src={freeDeliveryIcon} />
                Free Delivery
            </li>
        );
    }

    render() {
        const classes = classNames({
            [styles.root]: true,
            [styles.stacked]: this.props.stacked,
        });
        return (
            <ul className={classes}>
                {this.getTrialVP()}
                {this.getWarrantyVP()}
                {this.getDeliveryVP()}
            </ul>
        );
    }
}
