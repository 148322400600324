import React from "react";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { formatDistanceWithSuffix } from "tsi-common-react/src/apps/retail/helpers";
import { formatPhoneNumber } from "tsi-common-react/src/utils/format";
import tpStoreTile from "../../img/retail-locator/tp-store-tile.jpg";
import locationIcon from "../../img/retail-locator/location-icon.png";
import phoneIcon from "../../img/retail-locator/phone-icon.png";
import hoursIcon from "../../img/retail-locator/hours-icon.png";

interface IProps {
    store: IRetailStoreWithDistance;
}

interface IState {}

export class FlagshipStore extends React.Component<IProps, IState> {
    render() {
        // Convert distance from meters to miles
        const phoneInUSFormat = formatPhoneNumber(this.props.store.phone);
        return (
            <div className="pdp-modal-tpstore pdp-modal__block">
                <h5 className="pdp-modal__subtitle">
                    {gettext("Your Closest Tempur-Pedic Store")}
                </h5>
                <div className="pdp-modal-tpstore__img-wrapper">
                    <img
                        className="pdp-modal-tpstore__img responsive-img"
                        src={tpStoreTile}
                    />
                    <div className="pdp-modal-tpstore__miles">
                        {formatDistanceWithSuffix(this.props.store.distance)}
                    </div>
                </div>
                <div className="pdp-modal-tpstore__info">
                    <h4 className="pdp-modal-tpstore__name">
                        {this.props.store.name}
                    </h4>
                    <div className="pdp-modal-tpstore__address">
                        <div>
                            <img
                                src={locationIcon}
                                alt={gettext("Location Icon")}
                            />
                            <span className="pdp-modal-tpstore__marker al-more-ways-to-buy-directions">
                                {this.props.store.address}&nbsp;
                                {this.props.store.address2}&nbsp;
                                {this.props.store.city},{" "}
                                {this.props.store.state}
                            </span>
                        </div>
                        {phoneInUSFormat && (
                            <div>
                                <img
                                    src={phoneIcon}
                                    alt={gettext("Phone Icon")}
                                />
                                <a
                                    href={`tel:${this.props.store.phone}`}
                                    className="pdp-modal-tpstore__phone pdp-modal__link"
                                >
                                    {phoneInUSFormat}
                                </a>
                            </div>
                        )}
                        <div>
                            <img src={hoursIcon} alt={gettext("Hours Icon")} />
                            <div className="pdp-modal-tpstore__time">
                                {/*TODO: WTH? Why is this hardcoded? */}
                                {gettext("Today")}: {gettext("10AM")} -{" "}
                                {gettext("9PM")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
