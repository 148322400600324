import { Dispatch } from "@reduxjs/toolkit";
import { IMattressMatchResults } from "../../models/catalogue.interfaces";
import { Action, Mattress_Size, Step, getStepNameFromIdx } from "./constants";
import {
    IActionNavigate,
    IActionSelectOption,
    IActionSelectSize,
    IActionSelectHeight,
    IActionSelectWeight,
    IAction,
    IActionLoadedResults,
} from "./reducers.interfaces";
import { IAction as ICommonAction } from "../common/reducers.interfaces";
import { Dispatchers as CommonDispatchers } from "../common/dispatchers";

export class Dispatchers {
    dispatch: Dispatch<IAction>;
    common: CommonDispatchers;

    constructor(dispatch: Dispatch<IAction | ICommonAction>) {
        this.dispatch = dispatch;
        this.common = new CommonDispatchers(dispatch);
    }

    public readonly onSelectHeight = (selectedHeight: number) => {
        this.dispatch<IActionSelectHeight>({
            type: Action.SELECT_HEIGHT,
            payload: {
                selectedHeight: selectedHeight,
            },
        });
    };

    public readonly onSelectWeight = (selectedWeight: number) => {
        this.dispatch<IActionSelectWeight>({
            type: Action.SELECT_WEIGHT,
            payload: {
                selectedWeight: selectedWeight,
            },
        });
    };

    public readonly onSelectOption = (selectedValue: number, idx: Step) => {
        this.dispatch<IActionSelectOption>({
            type: Action.SELECT_OPTION,
            payload: {
                selectedValue: selectedValue,
                optionType: getStepNameFromIdx(idx),
            },
        });
    };
    public readonly onSelectSize = (selectedSize: Mattress_Size) => {
        this.dispatch<IActionSelectSize>({
            type: Action.SELECT_SIZE,
            payload: {
                selectedSize: selectedSize,
            },
        });
    };

    public readonly onNavigate = (step: Step) => {
        this.dispatch<IActionNavigate>({
            type: Action.NAVIGATE,
            payload: {
                currentScreen: step,
            },
        });
    };

    public readonly loadedResults = (loadedResults: IMattressMatchResults) => {
        this.dispatch<IActionLoadedResults>({
            type: Action.LOADED_RESULTS,
            payload: {
                loadedResults: loadedResults,
            },
        });
    };
}
