/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import SVG from "react-inlinesvg";
import { Step, getStepNameFromIdx } from "../constants";
import { slugify } from "../../../utils/text";
import { IMattressOption } from "../models.interfaces";
import classNames from "classnames";
import { RichText } from "../../../common/RichText";
import { FormCheckbox } from "../../../forms";
import styles from "./Option.module.scss";
import { SVGLoader } from "../elements/SVGLoader";

import iconBackSleepers from "../../../../img/icons/mattress-selector/back-sleepers.svg";
import iconBudgetBalance from "../../../../img/icons/mattress-selector/budget-balance.svg";
import iconBudgetForever from "../../../../img/icons/mattress-selector/budget-forever.svg";
import iconBudgetPiggy from "../../../../img/icons/mattress-selector/budget-piggy.svg";
import iconCoolingHot from "../../../../img/icons/mattress-selector/cooling-hot.svg";
import iconCoolingMild from "../../../../img/icons/mattress-selector/cooling-mild.svg";
import iconCoolingNoHeat from "../../../../img/icons/mattress-selector/cooling-no-heat.svg";
import iconCouple from "../../../../img/icons/mattress-selector/couple.svg";
import iconExit from "../../../../img/icons/mattress-selector/exit.svg";
import iconFirm from "../../../../img/icons/mattress-selector/firm.svg";
import iconHybrid from "../../../../img/icons/mattress-selector/hybrid.svg";
import iconLightbulb from "../../../../img/icons/mattress-selector/lightbulb.svg";
import iconMedium from "../../../../img/icons/mattress-selector/medium.svg";
import iconMediumHybrid from "../../../../img/icons/mattress-selector/medium-hybrid.svg";
import iconMild from "../../../../img/icons/mattress-selector/mild.svg";
import iconNo from "../../../../img/icons/mattress-selector/no.svg";
import iconNoDisturb from "../../../../img/icons/mattress-selector/no-disturb.svg";
import iconNumberOne from "../../../../img/icons/mattress-selector/number-one.svg";
import iconNumberTwo from "../../../../img/icons/mattress-selector/number-two.svg";
import iconPartner from "../../../../img/icons/mattress-selector/partner.svg";
import iconPiggy from "../../../../img/icons/mattress-selector/piggy.svg";
import iconSideSleepers from "../../../../img/icons/mattress-selector/side-sleepers.svg";
import iconSleepProfile from "../../../../img/icons/mattress-selector/sleep-profile.svg";
import iconSnore from "../../../../img/icons/mattress-selector/snore.svg";
import iconSoft from "../../../../img/icons/mattress-selector/soft.svg";
import iconStomachSleepers from "../../../../img/icons/mattress-selector/stomach-sleepers.svg";
import { strings } from "../../../localization";

export const icons = {
    "back-sleepers": iconBackSleepers,
    "budget-balance": iconBudgetBalance,
    "budget-forever": iconBudgetForever,
    "budget-piggy": iconBudgetPiggy,
    "cooling-hot": iconCoolingHot,
    "cooling-mild": iconCoolingMild,
    "cooling-no-heat": iconCoolingNoHeat,
    "couple": iconCouple,
    "exit": iconExit,
    "firm": iconFirm,
    "hybrid": iconHybrid,
    "lightbulb": iconLightbulb,
    "medium": iconMedium,
    "medium-hybrid": iconMediumHybrid,
    "mild": iconMild,
    "no": iconNo,
    "no-disturb": iconNoDisturb,
    "number-one": iconNumberOne,
    "number-two": iconNumberTwo,
    "partner": iconPartner,
    "piggy": iconPiggy,
    "side-sleepers": iconSideSleepers,
    "sleep-profile": iconSleepProfile,
    "snore": iconSnore,
    "soft": iconSoft,
    "stomach-sleepers": iconStomachSleepers,
} as const;

const DEFAULT_DESCRIPTION: { [key in Step]?: string } = {
    [Step.FEEL]:
        strings.get("MATTRESS_SELECTOR_FEEL_DEFAULT_DESCRIPTION") || "",
    [Step.COOL]:
        strings.get("MATTRESS_SELECTOR_COOL_DEFAULT_DESCRIPTION") || "",
    [Step.BUDGET]:
        strings.get("MATTRESS_SELECTOR_BUDGET_DEFAULT_DESCRIPTION") || "",
};

const iconExists = (step: string): step is keyof typeof icons => {
    return Object.prototype.hasOwnProperty.call(icons, step);
};

const getIcon = (trackerStep: string): string | null => {
    const step = trackerStep.toLowerCase().replace(/\s+/g, "-");
    return iconExists(step) ? icons[step] : null;
};

interface IProps {
    [key: string]: any;
    onSelectOption: (x: number, stepIdx: Step) => void;
    selectedOption: number;
    selectedFirmness: number;
    selectedCoolness: number;
    selectedBudget: number;
    stepIdx: Step;
    options: IMattressOption[];
}
interface IState {
    [key: string]: any;
    recommendations: string[] | undefined;
    selectedOption: number;
    selectedFirmness: number;
    selectedCoolness: number;
    selectedBudget: number;
    description: string | undefined;
}

export class MattressOption extends React.Component<IProps, IState> {
    private getDefaultDescription(step: Step) {
        return step ? DEFAULT_DESCRIPTION[step] : "";
    }
    default = {
        level: 0,
        recommendations: [],
        description: this.getDefaultDescription(this.props.stepIdx),
    };

    public state: IState = {
        recommendations: [],
        selectedOption: this.props.selectedOption || this.default.level,
        selectedFirmness:
            this.props.stepIdx === Step.FEEL
                ? this.props.selectedOption
                : this.props.selectedFirmness,
        selectedCoolness:
            this.props.stepIdx === Step.COOL
                ? this.props.selectedOption
                : this.props.selectedCoolness,
        selectedBudget:
            this.props.stepIdx === Step.BUDGET
                ? this.props.selectedOption
                : this.props.selectedBudget,
        description: this.default.description,
    };
    componentDidMount() {
        if (this.props.selectedOption) {
            this.updateRecommendations(
                this.props.options[this.props.selectedOption],
            );
        }
    }

    private getHeading(step: Step) {
        switch (step) {
            case Step.FEEL:
                return strings.get("MATTRESS_SELECTOR_FEEL_HEADING");
            case Step.COOL:
                return strings.get("MATTRESS_SELECTOR_COOL_HEADING");
            case Step.BUDGET:
                return strings.get("MATTRESS_SELECTOR_BUDGET_HEADING");
        }
        return;
    }

    private readonly resetSelection = () => {
        this.setState(
            {
                selectedOption: this.default.level,
                description: this.default.description,
            },
            () => {
                this.updateRecommendations();
                this.props.onSelectOption(
                    this.default.level,
                    this.props.stepIdx,
                );
            },
        );
    };
    private readonly onClick = async (option: IMattressOption) => {
        const isSelected = this.state.selectedOption === option.level;

        if (isSelected) {
            this.resetSelection();
        } else {
            this.setState(
                {
                    selectedOption: !isSelected
                        ? option.level
                        : this.default.level,
                    selectedFirmness:
                        this.props.stepIdx === Step.FEEL
                            ? this.state.selectedOption
                            : this.props.selectedFirmness,
                    selectedCoolness:
                        this.props.stepIdx === Step.COOL
                            ? this.state.selectedOption
                            : this.props.selectedCoolness,
                    selectedBudget:
                        this.props.stepIdx === Step.BUDGET
                            ? this.state.selectedOption
                            : this.props.selectedBudget,
                    description: !isSelected
                        ? option.description || this.default.description
                        : this.default.description,
                },
                () => {
                    this.updateRecommendations(option);
                    this.props.onSelectOption(option.level, this.props.stepIdx);
                },
            );
        }
    };
    private buildOptions(option: IMattressOption) {
        const selectedStep = `selected${getStepNameFromIdx(
            this.props.stepIdx,
        )}`;
        const classes = classNames({
            [styles.listItem]: true,
            [styles.checked]: this.props[selectedStep] === option.level,
        });
        const img = option.icon ? option.icon : option.label;
        const icon = getIcon(slugify(img));
        return (
            <li key={option.label} className={classes}>
                <FormCheckbox
                    className="radio"
                    checked={this.props[selectedStep] === option.level}
                    id={option.label}
                    name={option.label}
                    value={option.level.toString()}
                    onChange={() => {
                        this.onClick(option);
                    }}
                    required={true}
                    readOnly
                />
                <label htmlFor={option.label} aria-selected={false}>
                    {icon && (
                        <SVG
                            aria-hidden="true"
                            src={icon}
                            title={gettext(option.label)}
                            className={getStepNameFromIdx(this.props.stepIdx)}
                            loader={<SVGLoader />}
                        />
                    )}
                    <div>{gettext(option.label)}</div>
                </label>
            </li>
        );
    }
    private updateRecommendations(selected?: IMattressOption) {
        this.setState({
            recommendations: selected
                ? selected.recommendations
                : this.default.recommendations,
        });
    }
    private buildRecommendations(recommendation: string, idx: number) {
        const icon = getIcon(slugify(recommendation));
        return (
            <div key={idx} className={styles.recommendation}>
                {icon && (
                    <SVG
                        aria-hidden="true"
                        src={icon}
                        title={gettext(recommendation)}
                        loader={<SVGLoader className="small" />}
                    />
                )}
                {gettext(recommendation)}
            </div>
        );
    }
    render() {
        const classes = classNames({
            [styles.list]: true,
            [styles.quad]: this.props.options.length > 3,
        });
        return (
            <>
                <fieldset className={styles.fieldset}>
                    <legend>
                        <span>{this.getHeading(this.props.stepIdx)}</span>
                    </legend>
                    <ul className={classes}>
                        {this.props.options.map((x) => {
                            return this.buildOptions(x);
                        })}
                    </ul>
                </fieldset>
                {this.state.description && (
                    <section className={styles.optionDescription}>
                        <RichText html={this.state.description}></RichText>
                    </section>
                )}

                {this.state.recommendations && (
                    <div className={styles.recommendations}>
                        {this.state.recommendations.length > 0 && (
                            <>
                                {" "}
                                Recommended for:
                                <div className={styles.container}>
                                    {this.state.recommendations.map(
                                        (recommendation, idx) => {
                                            return this.buildRecommendations(
                                                recommendation,
                                                idx,
                                            );
                                        },
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </>
        );
    }
}
