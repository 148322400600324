import React from "react";
import styles from "./NoResults.module.scss";

interface IProps {
    retailerName: string;
}

export const NoResults = (props: IProps) => {
    const tmpl = gettext("There are no %s within your search criteria");
    const text = interpolate(tmpl, [props.retailerName]);
    return (
        <div className={styles.root}>
            <p>{text}</p>
        </div>
    );
};
