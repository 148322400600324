import React from "react";
import classNames from "classnames";
import { IProduct } from "../../../models/catalogue.interfaces";
import { Image } from "../../../common/Image";

interface IProps {
    rootProduct: IProduct;
    isSelected: boolean;
    onClick: () => void;
}

interface IState {}

export class GiftThumbnail extends React.Component<IProps, IState> {
    render() {
        const image = this.props.rootProduct.images.filter((img) => {
            return img.role === "Product Tile";
        })[0];
        if (!image) {
            console.error(
                `Tile image is missing for product ${this.props.rootProduct.title}`,
            );
            return null;
        }
        const thumbnailClasses = classNames({
            "gift-thumbnail": true,
            "gift-thumbnail--selected": this.props.isSelected,
        });
        const altText = interpolate(gettext("Select %s"), [
            this.props.rootProduct.title,
        ]);
        return (
            <li className={thumbnailClasses}>
                <button
                    type="button"
                    title={altText}
                    onClick={this.props.onClick}
                >
                    <Image src={image.original} alt={altText} />
                </button>
            </li>
        );
    }
}
