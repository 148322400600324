import React from "react";
import Modal from "react-modal";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { ajax } from "tsi-common-react/src/utils/ajax";
import { ISurveyModalPostData } from "../models.interfaces";
import iconXClose from "../../svg/x-close.svg";

interface IProps {
    email: string;
    isOpen: boolean;
    onClose: () => void;
}

interface IState {
    rating: number | null;
}

export class LocatorSurveyModal extends React.Component<IProps, IState> {
    state: IState = {
        rating: null,
    };

    private readonly possibleRatings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    private readonly onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!this.state.rating) {
            return;
        }
        const data: ISurveyModalPostData = {
            brand: "TP",
            program: "survey",
            email: this.props.email,
            surveyModalResult: `${this.state.rating}`,
        };
        await ajax
            .put("https://api.promosis.com/api/v1/tsi-2263-survey/1870")
            .send(data);
        this.props.onClose();
    };

    private buildRatings() {
        const elems = this.possibleRatings.map((rating) => {
            const classes = classNames({
                "br-current": this.state.rating === rating,
            });
            return (
                <a
                    key={rating}
                    data-rating-value={rating}
                    data-rating-text={rating}
                    className={classes}
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            rating: rating,
                        });
                    }}
                >
                    {rating}
                </a>
            );
        });
        return elems;
    }

    render() {
        return (
            <Modal
                aria={{
                    modal: true,
                }}
                style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxHeight: "95%",
                        maxWidth: "542px",
                        width: "96%",
                        overflow: "auto",
                        padding: "40px",
                    },
                }}
                contentLabel="Survey"
                portalClassName="locator"
                className="locations store-locator-survey modal"
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onClose}
                role="dialog"
            >
                <button
                    aria-label={gettext("Close")}
                    className="modal-close button--ghost"
                    onClick={this.props.onClose}
                >
                    <SVG
                        aria-hidden="true"
                        className="modal-close__icon"
                        src={iconXClose}
                        title={gettext("Close Icon")}
                    />
                </button>
                <div className="send__content survey__carousel">
                    <h2>Your results are on the way&hellip;</h2>
                    <p className="modal-intro">
                        One quick question. How was your online experience
                        today?
                    </p>
                    <form className="cell2-form" onSubmit={this.onSubmit}>
                        <fieldset>
                            <legend className="ada-screenreader-only">
                                Rate your experience
                            </legend>
                            <div className="br-wrapper br-theme-bars-square">
                                <div className="br-widget">
                                    {this.buildRatings()}
                                </div>
                            </div>
                            <div className="form__field form__field--submit">
                                <input
                                    id="survey-cell2-submit-email"
                                    type="submit"
                                    value="Continue"
                                    className="button al-store-locator__button--continue"
                                    disabled={!this.state.rating}
                                />
                            </div>
                        </fieldset>
                    </form>
                </div>
            </Modal>
        );
    }
}
