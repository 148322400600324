import React from "react";
import { roundReviewRating } from "../../../utils/math";
import SVG from "react-inlinesvg";
import styles from "./Rating.module.scss";
import starIcon from "../../../../img/icons/star.svg";

interface IProps {
    rating: number;
}
export class Rating extends React.Component<IProps> {
    render() {
        return (
            <div>
                <SVG
                    className={`${styles.star} star-rating__star`}
                    aria-hidden="true"
                    src={starIcon}
                    title={gettext("rating star")}
                />{" "}
                {roundReviewRating(this.props.rating)} / 5
            </div>
        );
    }
}
