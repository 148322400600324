import React from "react";
import classNames from "classnames";
import { IProduct } from "tsi-common-react/src/models/catalogue.interfaces";
import { trackAddToBasketEvent } from "tsi-common-react/src/utils/analytics";
import format from "tsi-common-react/src/utils/format";
import RatingGraphic from "tsi-common-react/src/common/RatingGraphic";
import { Image } from "tsi-common-react/src/common/Image";
import { Link } from "tsi-common-react/src/common/Link";
import { IBasketLineSuggestionProps } from "./BasketLineSuggestion.interfaces";
import { Dinero } from "dinero.js";
import { getDinero, getMonthlyPrice } from "tsi-common-react/src/utils/money";
import Trans from "tsi-common-react/src/common/Trans";

interface IState {}

export class BasketLineSuggestionB extends React.Component<
    IBasketLineSuggestionProps,
    IState
> {
    private addToBasket() {
        this.props.addBasketLine(this.props.product.url, 1);
        trackAddToBasketEvent(this.props.product, 1);
    }

    private scrollToFinePrint(event: React.MouseEvent<HTMLElement>) {
        if (event) {
            event.preventDefault();
        }

        const finePrint = document.querySelector("#basket-lines__fine-print");
        finePrint?.scrollIntoView({ behavior: "smooth" });
    }

    private buildImage(product: IProduct) {
        // Use the product image if one exists else try the parent image
        let image = product.images.slice().shift();
        if (!image && product.parent) {
            image = product.parent.images.slice().shift();
        }
        return image ? (
            <Link
                href={product.parent?.link || product.link}
                title={interpolate(gettext("Learn more about %s"), [
                    product.title,
                ])}
            >
                <Image
                    alt={product.title}
                    src={image.original}
                    className="basket-line__graphic__image"
                />
            </Link>
        ) : null;
    }

    private buildOptions() {
        const attributes = this.props.product.attributes;
        const optionKeys = attributes.product_options?.value || [];
        if (optionKeys.length <= 0) {
            return null;
        }

        const options = optionKeys.map((key) => {
            const attr = attributes[key];
            if (!attr) {
                return null;
            }
            return (
                <li key={key} className="basket-suggestion__details__option">
                    <span>{attr.name}</span>: <strong>{attr.value}</strong>
                </li>
            );
        });

        return (
            <div className="basket-suggestion__details__options">
                <ul>{options}</ul>
            </div>
        );
    }

    private calcPlanMonths(total: Dinero) {
        const plansHTML: HTMLElement[] = [].slice.call(
            document.querySelectorAll(".financing-plan-table__plan"),
        );
        const availablePlans = plansHTML.filter((plan) => {
            return total.greaterThanOrEqual(
                getDinero(plan.dataset.threshold || ""),
            );
        });
        const bestPlan = availablePlans[availablePlans.length - 1];
        return bestPlan ? parseInt(bestPlan.dataset.length || "", 10) : null;
    }

    private buildStarRating(product: IProduct) {
        const rating = product.rating;
        const cardType = "cart";
        const numReviews = 5;
        const ratingNum = (
            <RatingGraphic
                rating={rating}
                numReviews={numReviews}
                onlyReviewsNum={false}
                cardClass={cardType}
                cardSize={"small"}
            />
        );
        return ratingNum;
    }

    private buildAddToBasketButton() {
        let addToCartLabel: string = gettext("Add to cart");
        let isPreorder = false;
        if (
            this.props.product.attributes &&
            this.props.product.attributes.preorder &&
            this.props.product.attributes.preorder.value
        ) {
            addToCartLabel = gettext("Pre-Order");
            isPreorder = true;
        }

        const buttonClasses = classNames({
            "button": true,
            "button--secondary": isPreorder,
        });

        return (
            <button
                className={buttonClasses}
                onClick={this.addToBasket.bind(this)}
            >
                {addToCartLabel}
            </button>
        );
    }

    render() {
        const product = this.props.product;
        const rootProduct = product.parent || product;
        const onFinePrintClick = (event: React.MouseEvent<HTMLElement>) => {
            this.scrollToFinePrint(event);
        };

        let financing: JSX.Element | null = null;
        if (product.price.enable_per_month_pricing) {
            // Calculate Suggested plan based on (Cart total + Suggestion Product total) / (Highest plan that total qualifies for)
            const basketTotalPrice = getDinero(this.props.basketTotal);
            const extraProductPrice = getDinero(
                this.props.product.price.cosmetic_excl_tax || "0.00",
            );
            const computedTotalPrice = basketTotalPrice.add(extraProductPrice);
            const planMonths = this.calcPlanMonths(computedTotalPrice);
            if (planMonths) {
                const computedMonthlyPrice = getMonthlyPrice(
                    getDinero(
                        this.props.product.price.cosmetic_excl_tax || "0.00",
                    ),
                    planMonths,
                );
                financing = (
                    <p>
                        <button
                            onClick={onFinePrintClick}
                            className="basket-suggestion__financing-button"
                        >
                            or{" "}
                            <span className="basket-suggestion__financing--underline">
                                {format.money(computedMonthlyPrice)}/
                                {gettext("mo")}.
                            </span>
                            <sup>2</sup> {gettext("for")}{" "}
                            <span className="basket-suggestion__financing--underline">
                                {interpolate(gettext("%s months"), [
                                    `${planMonths}`,
                                ])}
                            </span>
                            <sup>1</sup>
                        </button>
                    </p>
                );
            }
        }

        return (
            <div
                role="region"
                aria-label="Upsell Carousel"
                className="basket-suggestion basket-suggestion--version-b"
            >
                <div className="basket-suggestion__row basket-suggestion__row--version-b">
                    <h4>
                        <Trans
                            fmtString={gettext(
                                "Complete Your Tempur-Pedic <Superscript>®</Superscript> Sleep Experience",
                            )}
                            data={{
                                Superscript: (content) => (
                                    <sup key="1">{content}</sup>
                                ),
                            }}
                        />
                    </h4>
                </div>
                <div className="basket-suggestion__row">
                    <div className="basket-suggestion__graphic">
                        {this.buildImage(product)}
                    </div>
                    <div className="basket-suggestion__details basket-suggestion__details--version-b">
                        <h4 className="basket-suggestion__details-title">
                            <Link href={rootProduct.link}>
                                {rootProduct.title}
                            </Link>
                        </h4>
                        <div className="basket-suggestion__details__tag">
                            {gettext("Best Seller")}
                        </div>
                        {this.buildStarRating(product)}
                        {this.buildOptions()}
                    </div>
                    <div className="basket-suggestion__price basket-suggestion__price--version-b">
                        <p>{format.money(product.price.cosmetic_excl_tax)}</p>
                        <div className="basket-suggestion__financing">
                            {financing}
                        </div>
                        {this.buildAddToBasketButton()}
                    </div>
                </div>
            </div>
        );
    }
}
