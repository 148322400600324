import React from "react";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import {
    IUserConfigurableBundle,
    IProduct,
} from "../../../models/catalogue.interfaces";
import { money } from "../../../utils/format";
import { PriceType, getProductPrice } from "../../../utils/catalogue";
import { getProductVariant } from "../../selectors";
import { ProductUnavailable } from "../../../common/ProductUnavailable";
import { Dispatchers } from "../dispatchers";
import { IGiftSelection } from "../reducers.interfaces";
import { GiftThumbnail } from "./GiftThumbnail";
import { GiftMainImage } from "./GiftMainImage";
import { GiftOptions } from "./GiftOptions";
import { Trans } from "../../../common/Trans";

interface IOwnProps {
    bundle: IUserConfigurableBundle;
    giftID: string;
    giftIdx: number;
    qtyIdx: number;
}

interface IReduxProps {
    selectedRootProduct: IProduct | null;
    selectedVariant: IProduct | null;
    optionValues: IGiftSelection["optionValues"];
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class ConfigureGiftComponent extends React.Component<IProps, IState> {
    componentDidMount() {
        this.setDefaultRootProduct();
    }

    private setDefaultRootProduct() {
        // Find default root and variant
        const defaultRoot = this.props.bundle.suggested_range_products[0];
        if (!defaultRoot) {
            return;
        }
        this.props.dispatchers.setGiftRootProduct(
            this.props.giftID,
            this.props.bundle.id,
            defaultRoot,
        );
    }

    private buildThumbnails() {
        return this.props.bundle.suggested_range_products.map((p) => {
            return (
                <GiftThumbnail
                    key={`${p.id}`}
                    rootProduct={p}
                    isSelected={
                        !!this.props.selectedRootProduct &&
                        this.props.selectedRootProduct.id === p.id
                    }
                    onClick={this.props.dispatchers.setGiftRootProduct.bind(
                        this,
                        this.props.giftID,
                        this.props.bundle.id,
                        p,
                    )}
                />
            );
        });
    }

    private buildPrice() {
        if (
            !this.props.selectedVariant ||
            !this.props.selectedVariant.availability.is_available_to_buy
        ) {
            return <ProductUnavailable variant={this.props.selectedVariant} />;
        }
        const displayPrice = getProductPrice(this.props.selectedVariant.price, {
            priceType: PriceType.RETAIL,
            includePostDiscountAddons: true,
            quantity: 1,
        });
        return (
            <>
                <h3>{this.props.selectedVariant.title}</h3>
                <p>
                    <strong>{gettext("FREE")}</strong>
                    &nbsp;
                    <Trans
                        fmtString={gettext("Was <Price></Price>")}
                        data={{
                            Price: () => (
                                <span key="1">{money(displayPrice)}</span>
                            ),
                        }}
                    />
                </p>
            </>
        );
    }

    render() {
        const totalOptionCount =
            this.props.bundle.suggested_range_products.reduce<number>(
                (memo, bundle) => {
                    return memo + bundle.children.length;
                },
                this.props.bundle.suggested_range_products.length,
            );
        let giftCTA: JSX.Element | null = null;
        let thumbnails: JSX.Element | null = null;
        let options: JSX.Element | null = null;
        if (totalOptionCount > 1) {
            giftCTA = <p>{gettext("Choose your gift:")}</p>;
            thumbnails = (
                <ul className="configure-gift__thumbnails">
                    {this.buildThumbnails()}
                </ul>
            );
            options = (
                <div className="configure-gift__option-selectors">
                    <GiftOptions
                        giftID={this.props.giftID}
                        rootProduct={this.props.selectedRootProduct}
                        variant={null}
                        optionValues={this.props.optionValues || {}}
                        onOptionChange={this.props.dispatchers.setGiftOptionValue.bind(
                            this,
                            this.props.giftID,
                        )}
                    />
                </div>
            );
        }
        const sectionTitle = interpolate(gettext("Gift %s"), [
            `${this.props.giftIdx}`,
        ]);
        return (
            <section className="configure-gift" aria-label={sectionTitle}>
                <header>
                    <h2>{sectionTitle}</h2>
                    {giftCTA}
                </header>
                <div className="configure-gift__content">
                    <div className="configure-gift__left">
                        <GiftMainImage
                            rootProduct={this.props.selectedRootProduct}
                            variant={this.props.selectedVariant}
                        />
                    </div>
                    <div className="configure-gift__right">
                        {thumbnails}
                        <div className="configure-gift__variant-details">
                            {this.buildPrice()}
                        </div>
                        {options}
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    if (ownProps.bundle.suggested_range_products.length <= 0) {
        throw new Error("Can not configure gift with no suggested products");
    }
    const state = rootState.checkout;
    const giftConfig: IGiftSelection = state.gifts.gifts[ownProps.giftID] || {};
    const selectedRootProductID = giftConfig
        ? giftConfig.selectedRootProduct
        : ownProps.bundle.suggested_range_products[0].id;
    const selectedRootProduct =
        ownProps.bundle.suggested_range_products.find((p) => {
            return p.id === selectedRootProductID;
        }) || null;
    const optionValues = giftConfig.optionValues || {};
    return {
        selectedRootProduct: selectedRootProduct,
        selectedVariant: selectedRootProduct
            ? getProductVariant([selectedRootProduct], optionValues)
            : null,
        optionValues: optionValues,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const ConfigureGift = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfigureGiftComponent);
