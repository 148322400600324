import React from "react";
import { FinancingModalTrigger } from "tsi-common-react/src/apps/financing/FinancingModalTrigger";
import { Form } from "tsi-common-react/src/forms/Form";
import { FormSubmit } from "tsi-common-react/src/forms/FormSubmit";
import { FormNumber } from "tsi-common-react/src/forms/FormNumber";
import { FormSelect } from "tsi-common-react/src/forms/FormSelect";
import { getDinero, getMonthlyPrice } from "tsi-common-react/src/utils/money";
import { format } from "tsi-common-react/src/utils/format";

interface IProps {}

interface IState {
    paymentAmount: string;
    paymentTerm: string;
    paymentAmountCalcd: string;
    paymentTermCalcd: string;
    paymentTotal: string;
}

export class FinanceCalculator extends React.Component<IProps, IState> {
    public state: IState = {
        paymentAmount: "4000",
        paymentTerm: "24",
        paymentAmountCalcd: "4000.00",
        paymentTermCalcd: "24",
        paymentTotal: "83.33",
    };

    private readonly onCalcPayment = (
        event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        event.preventDefault();
        this.setState({
            paymentTotal: format.money(
                getMonthlyPrice(
                    getDinero(this.state.paymentAmount),
                    parseInt(this.state.paymentTerm, 10),
                ),
            ),
            paymentAmountCalcd: String(
                parseInt(this.state.paymentAmount, 10).toFixed(2),
            ),
            paymentTermCalcd: this.state.paymentTerm,
        });
        return Promise.resolve();
    };

    private readonly onPaymentChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        this.setState({
            paymentAmount: event.currentTarget.value,
        });
    };

    private readonly onTermChange = (
        event: React.FormEvent<HTMLSelectElement>,
    ) => {
        this.setState({
            paymentTerm: event.currentTarget.value,
        });
    };

    componentDidMount() {
        const plansHTML = [].slice.call(
            document.querySelectorAll(".financing-plan-table__plan"),
        );
        const bestPlan: HTMLSpanElement = plansHTML[plansHTML.length - 1];
        const bestPlanLength = bestPlan.dataset.length;

        if (bestPlanLength) {
            this.setState({
                paymentTerm: bestPlanLength,
                paymentTermCalcd: bestPlanLength,
                paymentTotal: format.money(
                    getMonthlyPrice(
                        getDinero(this.state.paymentAmount),
                        parseInt(bestPlanLength, 10),
                    ),
                ),
            });
        }
    }

    private getTermMonths() {
        const plansHTML = [].slice.call(
            document.querySelectorAll(".financing-plan-table__plan"),
        );

        return plansHTML.map((plan: HTMLSpanElement) => {
            const planLength = plan.dataset.length
                ? parseInt(plan.dataset.length, 10)
                : 0;

            return {
                label: `${planLength} Months`,
                value: `${planLength}`,
            };
        });
    }

    render() {
        return (
            <div className="finance-calculator">
                <h3 className="finance-calculator__title">
                    {gettext("Monthly Payment Calculator")}
                </h3>
                <Form
                    className="finance-calculator-form"
                    onSubmit={this.onCalcPayment}
                >
                    <fieldset>
                        <legend className="ada-screenreader-only">
                            {gettext("Calculate Monthly Payment")}
                        </legend>
                        <FormNumber
                            name="finance-payment"
                            label="Enter Financed Amount"
                            id="finance-payment"
                            className="finance-calculator-form__payment"
                            onChange={this.onPaymentChange}
                            value={this.state.paymentAmount}
                            min={1}
                            max={1000000}
                        />
                        <p className="finance-calculator-form__for">
                            {gettext("for")}
                        </p>
                        <FormSelect
                            name="finance-terms"
                            id="finance-terms"
                            label="Term Length Dropdown"
                            className="finance-calculator-form__terms"
                            value={this.state.paymentTerm}
                            onChange={this.onTermChange}
                            choices={this.getTermMonths()}
                        />
                        <FormSubmit
                            value="Calculate Monthly Payment"
                            className="finance-calculator-form__submit al-financing-page__calculator--calc-payment"
                        />
                    </fieldset>
                </Form>
                <div className="finance-calculator__payment">
                    <a href="#terms_and_conditions">
                        <span className="finance-calculator__payment--underlined">
                            {this.state.paymentTotal}/{gettext("mo")}.
                        </span>
                        <sup>2</sup>
                        <span className="ada-screenreader-only">
                            {gettext(
                                "Additional information about Terms and Conditions",
                            )}
                        </span>
                    </a>
                    &nbsp;{gettext("for")}&nbsp;
                    <a href="#terms_and_conditions">
                        <span className="finance-calculator__payment--underlined">
                            {this.state.paymentTermCalcd} {gettext("months")}
                        </span>
                        <sup>1</sup>
                        <span className="ada-screenreader-only">
                            {gettext(
                                "Additional information about Terms and Conditions",
                            )}
                        </span>
                    </a>
                    <br />
                    {interpolate(gettext("(total payments of $%s)"), [
                        this.state.paymentAmountCalcd,
                    ])}
                </div>
                <p className="finance-calculator__disclaimer">
                    {gettext(
                        "Check table for details about minimum financing requirements",
                    )}
                </p>
                <FinancingModalTrigger
                    modalType="prequal-app"
                    className="button button--inverse finance-calculator__button al-financing-page__calculator--check-prequalify"
                >
                    {gettext("Check if you Pre-Qualify")}
                </FinancingModalTrigger>
            </div>
        );
    }
}
