import React from "react";
import classNames from "classnames";
import {
    RetailStoreEventType,
    RetailStoreEventCTAType,
} from "tsi-common-react/src/constants";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { formatDistance } from "tsi-common-react/src/apps/retail/helpers";
import { Link } from "tsi-common-react/src/common/Link";
import { ICMSFlagshipStorePage } from "../models.interfaces";
import { trackRetailStoreEvent } from "tsi-common-react/src/utils/analytics";
import { formatPhoneNumber } from "tsi-common-react/src/utils/format";
import { getTodayHoursKey } from "../utils";
import iconIconInfo from "../../img/retail-locator/icon-info.svg";
import iconIconDirections from "../../img/retail-locator/icon-directions.svg";

interface IProps {
    store: IRetailStoreWithDistance;
    cmsProps: ICMSFlagshipStorePage;
    isFocused: boolean;
    includeLocationInfo: boolean;
    onActivateMarker: () => void;
}

interface IState {}

export class FlagshipNearestStoresTile extends React.Component<IProps, IState> {
    // Monitor data layer events when clicking on store location details like directions, phone numbers.
    private onClickLocationInfo(
        ctaType: RetailStoreEventCTAType,
        textContent: string | null,
        event: React.MouseEvent<HTMLElement>,
    ) {
        event.stopPropagation();
        const element = event.currentTarget as HTMLElement;
        const linkText = textContent || element.textContent?.trim();
        const linkUrl = element.getAttribute("href");
        trackRetailStoreEvent(
            RetailStoreEventType.TEMPUR_STORE,
            this.props.store,
            ctaType,
            linkText,
            linkUrl,
        );
    }
    private buildLocationInfo() {
        if (!this.props.includeLocationInfo) {
            return;
        }
        const phoneInUSFormat = formatPhoneNumber(this.props.store.phone);
        // encode destination address for Directions link
        const destinationAddress = encodeURI(
            [
                this.props.store.address,
                this.props.store.address2,
                this.props.store.city,
                this.props.store.state,
                this.props.store.postal,
            ].join(" "),
        );
        return (
            <div className="loc__info">
                <div className="loc__contact">
                    <div className="loc__addr">
                        <span className="loc__addr1">
                            {this.props.store.address},{" "}
                            {this.props.store.address2}
                        </span>
                        <br />
                        <span className="loc__city">
                            {this.props.store.city}
                        </span>
                        ,&nbsp;
                        <span className="loc__state">
                            {this.props.store.state}
                        </span>
                        &nbsp;
                        <span className="loc__postal">
                            {this.props.store.postal}
                        </span>
                    </div>
                    <br />
                    <a
                        className="loc__phone"
                        href={`tel:${this.props.store.phone}`}
                        onClick={this.onClickLocationInfo.bind(
                            this,
                            RetailStoreEventCTAType.CALL,
                            null,
                        )}
                    >
                        {phoneInUSFormat}
                    </a>
                    <div className="loc__hours">
                        {gettext("Today:")}{" "}
                        {this.props.cmsProps[getTodayHoursKey()]}
                    </div>
                </div>
                <div className="loc__links">
                    <Link
                        className="loc__link loc__link--store-url"
                        href={this.props.cmsProps.meta.html_url}
                        title="Tempur-Pedic Store"
                    >
                        <img src={iconIconInfo} />
                    </Link>
                    <a
                        className="loc__link"
                        href={`https://www.google.com/maps/dir/?api=1&destination=${destinationAddress}`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={this.onClickLocationInfo.bind(
                            this,
                            RetailStoreEventCTAType.MAP_DIRECTIONS,
                            "Get icon directions",
                        )}
                    >
                        <img src={iconIconDirections} />
                    </a>
                </div>
            </div>
        );
    }
    private onClickStoreTile() {
        this.props.onActivateMarker();
        trackRetailStoreEvent(
            RetailStoreEventType.TEMPUR_STORE,
            this.props.store,
        );
    }
    render() {
        const headerStyles: React.CSSProperties = {
            backgroundImage: this.props.cmsProps.storefront_thumbnail
                ? `url(${this.props.cmsProps.storefront_thumbnail.url})`
                : undefined,
        };
        const tileClasses = classNames({
            "locations__list-item": true,
            "list-focus": this.props.isFocused,
        });
        const headerClasses = classNames({
            "loc__header": true,
            "loc__header--hidden": this.props.includeLocationInfo,
        });
        return (
            <li
                onClick={this.onClickStoreTile.bind(this)}
                className={tileClasses}
            >
                <Link
                    href={this.props.cmsProps.meta.html_url}
                    className={headerClasses}
                    style={headerStyles}
                >
                    <div className="loc__headlines">
                        <h4 className="loc__headline">
                            {this.props.store.city}, {this.props.store.state}
                        </h4>
                        <h5 className="loc__subheadline">
                            {this.props.cmsProps.shopping_district}
                        </h5>
                    </div>
                </Link>
                <div className="loc__milesaway">
                    <span className="loc__miles">
                        <span className="loc__distance">
                            {formatDistance(this.props.store.distance)}
                        </span>
                        <br />
                        <span className="loc__length">{gettext("miles")}</span>
                    </span>
                </div>
                {this.buildLocationInfo()}
            </li>
        );
    }
}
