import React from "react";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { getDinero } from "tsi-common-react/src/utils/money";

// Render Financing Modal for Product Cards
dynamicPlaceComponent(
    '[data-place-react="card-financing-modal"]',
    async (elem) => {
        const { CardFinancingModal } = await import(
            "tsi-common-react/src/common/CardFinancingModal"
        );
        const productPrice = getDinero(elem.dataset.price);
        const productTitle = elem.dataset.title || "";
        return (
            <CardFinancingModal
                price={productPrice}
                title={productTitle}
                linkText={gettext("Financing available with qualifying order.")}
            />
        );
    },
);
