import React from "react";
import { IImageURL } from "tsi-common-react/src/models/nominals";
import {
    IProduct,
    IConcreteBundle,
} from "tsi-common-react/src/models/catalogue.interfaces";
import format from "tsi-common-react/src/utils/format";
import { addProductToBasket } from "tsi-common-react/src/apps/configurator/actions";
import { Image } from "tsi-common-react/src/common/Image";
import { urls } from "tsi-common-react/src/utils/urls";
import { TinyProduct } from "./TinyProduct";
import { trackAddToBasketEvent } from "tsi-common-react/src/utils/analytics";
import { getDinero } from "tsi-common-react/src/utils/money";

interface IProps {
    product: IProduct;
    variant: IProduct;
    bundle: IConcreteBundle;
    bundleProductIndex?: number;
}

interface IState {
    buyIsDisabled: boolean;
    addToBasketButtonText: string;
}

/**
 * A two-product bundle shown on the PDP.
 *
 * Displays both products together, different from the InContextBundle, which
 * only displays one product, relying on page-context to imply the other product.
 */
export class OutOfContextBundle extends React.Component<IProps, IState> {
    static defaultProps: Partial<IProps> = {
        bundleProductIndex: 0,
    };

    public state: IState = {
        buyIsDisabled: false,
        addToBasketButtonText: "Buy Together",
    };

    addToBasket() {
        this.setState({
            buyIsDisabled: true,
        });

        const p1 = addProductToBasket(this.props.variant.url, 1);
        const p2 = addProductToBasket(
            this.props.bundle.suggested_products[
                this.props.bundleProductIndex || 0
            ].url,
            1,
        );

        Promise.all([p1, p2]).then(() => {
            this.setState({ addToBasketButtonText: "Added" });
            trackAddToBasketEvent(this.props.variant, 1);
            trackAddToBasketEvent(
                this.props.bundle.suggested_products[
                    this.props.bundleProductIndex || 0
                ],
                1,
            );
            urls.navigateTo("basket-summary");
        });
    }

    render() {
        const suggestedProduct =
            this.props.bundle.suggested_products[
                this.props.bundleProductIndex || 0
            ];
        const rootSuggestedProduct =
            suggestedProduct.parent || suggestedProduct;
        const triggeringProduct = this.props.product;
        const triggeringVariant = this.props.variant;
        const bundleGroup = this.props.bundle.bundle_group;
        const combinedPrice =
            triggeringVariant.price.cosmetic_excl_tax &&
            suggestedProduct.price.cosmetic_excl_tax
                ? getDinero(triggeringVariant.price.cosmetic_excl_tax).add(
                      getDinero(suggestedProduct.price.cosmetic_excl_tax),
                  )
                : null;

        // Figure out whether or not to display financing options for this bundle
        /*let combinedMonthlyPrice: Dinero;
        let termLength: string;
        if (triggeringVariant.price.per_month && suggestedProduct.price.per_month) {
            combinedMonthlyPrice = getDinero(triggeringVariant.price.per_month).add(getDinero(suggestedProduct.price.per_month));
            termLength = triggeringVariant.price.per_month_term_length;
        }

        let priceElem: JSX.Element = null;
        if (combinedMonthlyPrice && termLength) {
            priceElem = (
                <div className='pdp-bundle-info__price'>
                    Together {format.money(combinedPrice)} or {' '}
                    <Link href={urls.pageURL('finance-link')}>
                        <span className='pdp-bundle-info__price-per-month--underline'>
                        {format.money(combinedMonthlyPrice)}/mo.</span><sup>2</sup>{' '}
                        for{' '}
                        <span className='pdp-bundle-info__price-per-month--underline'>{termLength} months</span><sup>1</sup>
                    </Link>
                </div>
            );
        } else {*/
        // TODO: Fix math error for calculating financing.
        const priceElem: JSX.Element = (
            <div className="pdp-bundle-info__price">
                {interpolate(gettext("Together %s"), [
                    format.money(combinedPrice),
                ])}
            </div>
        );
        // }

        // Figure out the best image to display for this bundle
        let imageSrc: IImageURL | string | undefined = undefined;
        if (bundleGroup && bundleGroup.image) {
            imageSrc = bundleGroup.image;
        } else if (suggestedProduct.images.length > 0) {
            imageSrc = suggestedProduct.images[0].original;
        } else if (rootSuggestedProduct.images.length > 0) {
            imageSrc = rootSuggestedProduct.images[0].original;
        }

        return (
            <div className="pdp-bundle">
                <div className="pdp-bundle-images">
                    <div className="pdp-bundle__image--hero">
                        <Image
                            className="responsive-img pdp-bundle-img"
                            src={imageSrc}
                        />
                    </div>
                    <div className="pdp-bundle__group">
                        <TinyProduct
                            product={triggeringProduct}
                            variant={triggeringVariant}
                            last={false}
                        />
                        <TinyProduct
                            product={rootSuggestedProduct}
                            variant={suggestedProduct}
                            last={true}
                        />
                    </div>
                </div>
                <div className="pdp-bundle-info">
                    <div className="pdp-bundle-info__copy">
                        <div className="pdp-bundle-info__title">
                            {interpolate(gettext("%s and %s"), [
                                triggeringProduct.title,
                                rootSuggestedProduct.title,
                            ])}
                        </div>
                        <div className="pdp-bundle-info__desc">
                            {this.props.bundle.bundle_group
                                ? this.props.bundle.bundle_group.description
                                : null}
                        </div>
                        {priceElem}
                    </div>
                    <div className="pdp-bundle-info__cta">
                        <button
                            className="button pdp-bundle-info__button al-pdp-bundle__info-button"
                            disabled={this.state.buyIsDisabled}
                            onClick={() => {
                                this.addToBasket();
                            }}
                        >
                            {this.state.addToBasketButtonText}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
