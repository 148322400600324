import React from "react";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { HEIGHT, WEIGHT } from "../constants";
import { slugify } from "../../../utils/text";
import { IMattressBodyType, IMattressOption } from "../models.interfaces";
import { getViewportBreakpoint } from "../../../utils/detectMobile";
import { BreakPoint } from "../../../models/screen.interfaces";
import { FormCheckbox } from "../../../forms";
import styles from "./MattressBodyType.module.scss";
import iconShort from "../../../../img/icons/mattress-selector/short.svg";
import iconWeight from "../../../../img/icons/mattress-selector/weight.svg";
import iconLight from "../../../../img/icons/mattress-selector/light.svg";
import iconHeight from "../../../../img/icons/mattress-selector/height.svg";
import iconTall from "../../../../img/icons/mattress-selector/tall.svg";
import iconHeavy from "../../../../img/icons/mattress-selector/heavy.svg";
import { SVGLoader } from "../elements/SVGLoader";
import { strings } from "../../../localization";

const icons = {
    height: iconHeight,
    heavy: iconHeavy,
    light: iconLight,
    short: iconShort,
    tall: iconTall,
    weight: iconWeight,
} as const;

const iconExists = (step: string): step is keyof typeof icons => {
    return Object.prototype.hasOwnProperty.call(icons, step);
};

const getIcon = (trackerStep: string): string | null => {
    const step = trackerStep.toLowerCase().replace(/\s+/g, "-");
    return iconExists(step) ? icons[step] : null;
};

interface IProps {
    onSelectHeight: (n: number) => void;
    onSelectWeight: (n: number) => void;
    selectedHeight: number;
    selectedWeight: number;
}
interface IState {
    height: number | null;
    weight: number | null;
    description: string | null;
}
export class MattressSelectorBodyType extends React.Component<IProps, IState> {
    default = {
        height: 0,
        weight: 0,
        description: strings.get(
            "MATTRESS_SELECTOR_BODY_TYPE_DEFAULT_DESCRIPTION",
        ),
    };
    public state: IState = {
        height: this.props.selectedHeight || this.default.height,
        weight: this.props.selectedWeight || this.default.weight,
        description: this.default.description,
    };
    private readonly resetSelection = (optionType: string) => {
        this.setState(
            {
                height: optionType === "height" ? null : this.state.height,
                weight: optionType === "weight" ? null : this.state.weight,
            },
            () => {
                if (optionType === "height") {
                    this.props.onSelectHeight(this.default.height);
                } else {
                    this.props.onSelectWeight(this.default.weight);
                }
            },
        );
    };
    private readonly onClick = async (
        optionType: string,
        option: IMattressOption | IMattressBodyType,
    ) => {
        const isHeight = optionType === "height";

        if (isHeight) {
            if (this.state[optionType] === option.level) {
                this.resetSelection(optionType);
                return;
            }
        } else {
            if (this.state.weight === option.level) {
                this.resetSelection(optionType);
                return;
            }
        }

        this.setState(
            {
                height: isHeight ? option.level : this.state.height,
                weight: !isHeight ? option.level : this.state.weight,
                description: strings.get(
                    "MATTRESS_SELECTOR_BODY_TYPE_OPTION_DESCRIPTION",
                ),
            },
            () => {
                if (!isHeight) {
                    this.props.onSelectWeight(option.level);
                }
                if (isHeight) {
                    this.props.onSelectHeight(option.level);
                }
            },
        );
    };

    private buildOptions(
        optionType: string,
        option: IMattressOption | IMattressBodyType,
    ) {
        let isChecked = false;
        if (optionType === "height") {
            isChecked = this.state.height === option.level;
        }
        if (optionType === "weight") {
            isChecked = this.state.weight === option.level;
        }
        const classes = classNames({
            [styles.listItem]: true,
            [styles.checked]: isChecked,
        });
        const icon = option.icon ? getIcon(slugify(option.icon)) : null;
        return (
            <li key={option.level} className={classes}>
                <FormCheckbox
                    className="radio"
                    checked={isChecked}
                    id={option.icon}
                    name={optionType}
                    value={option.label}
                    onChange={() => {
                        this.onClick(optionType, option);
                    }}
                    required={true}
                    readOnly
                />

                {option.icon && (
                    <label htmlFor={option.icon} aria-selected={false}>
                        {icon && (
                            <SVG
                                aria-hidden="true"
                                src={icon}
                                title={gettext(option.label)}
                                loader={<SVGLoader />}
                            />
                        )}

                        <div>{gettext(option.label)}</div>
                    </label>
                )}
            </li>
        );
    }
    private buildOptionDescription() {
        return (
            <section
                className={styles.optionDescription}
                dangerouslySetInnerHTML={{
                    __html: `<p>${this.state.description}</p>`,
                }}
            ></section>
        );
    }
    private isMobileWidth() {
        return getViewportBreakpoint() <= BreakPoint.MEDIUM;
    }
    render() {
        return (
            <>
                <fieldset className={styles.heightContainer}>
                    <legend>
                        <span>{gettext("How tall are you?")}</span>
                    </legend>

                    <ul className={styles.list}>
                        {HEIGHT.map((height) => {
                            return this.buildOptions("height", height);
                        })}
                    </ul>
                </fieldset>
                {!this.isMobileWidth() && this.buildOptionDescription()}
                <fieldset className={styles.weightContainer}>
                    <legend>
                        <span>{gettext("What is your average weight?")}</span>
                    </legend>

                    <ul className={styles.list}>
                        {WEIGHT.map((weight) => {
                            return this.buildOptions("weight", weight);
                        })}
                    </ul>
                </fieldset>
                {this.isMobileWidth() && this.buildOptionDescription()}
            </>
        );
    }
}
