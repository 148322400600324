import { IReduxState } from "./reducers.interfaces";
import { ICustomerMatchPoint } from "./models.interfaces";
import {
    IMattressMatchResults,
    IProduct,
} from "../../models/catalogue.interfaces";
import { BUDGET } from "./constants";

export const formComplete = (state: IReduxState): boolean => {
    return Boolean(
        state.ui.selectedFirmness &&
            state.ui.selectedBudget &&
            state.ui.selectedHeight &&
            state.ui.selectedWeight,
    );
};

export const calculatedFirmness = (state: IReduxState): number => {
    if (state.ui.selectedFirmness) {
        return state.ui.selectedFirmness;
    }
    return 0;
};

export const userMatchPoint = (
    state: IReduxState,
): ICustomerMatchPoint | undefined => {
    return {
        optionValues: {
            budget_rank: state.ui.selectedBudget || BUDGET[1].level,
            firmness: calculatedFirmness(state),
        },
    };
};

export const matchEnabledProducts = (rootProducts: IProduct[]): IProduct[] => {
    return rootProducts.filter((p) => {
        return (
            !p.category_names.includes("Discontinued") &&
            !p.category_names.includes("Closeout") &&
            (p.attributes.firmness || true) &&
            (p.attributes.budget_rank || true)
        );
    });
};

export const mostSimilarProducts = (
    state: IReduxState,
): IMattressMatchResults | null => {
    if (!state.ui.loadedResults) {
        return null;
    }
    return state.ui.loadedResults;
};
