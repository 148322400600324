/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { slugify } from "../../../utils/text";
import {
    Step,
    Mattress_Size,
    stepArrayIdxs,
    getStepNameFromIdx,
} from "../constants";
import { defaults } from "../defaults";
import styles from "./MattressMatchNav.module.scss";
import iconMenuBodyType from "../../../../img/icons/mattress-selector/menu-body_type.svg";
import iconMenuBudget from "../../../../img/icons/mattress-selector/menu-budget.svg";
import iconMenuCool from "../../../../img/icons/mattress-selector/menu-cool.svg";
import iconMenuFeel from "../../../../img/icons/mattress-selector/menu-feel.svg";
import iconMenuResults from "../../../../img/icons/mattress-selector/menu-results.svg";

const icons = {
    "menu-body_type": iconMenuBodyType,
    "menu-budget": iconMenuBudget,
    "menu-cool": iconMenuCool,
    "menu-feel": iconMenuFeel,
    "menu-results": iconMenuResults,
} as const;

const iconExists = (step: string): step is keyof typeof icons => {
    return Object.prototype.hasOwnProperty.call(icons, step);
};

const getIcon = (trackerStep: string): string | null => {
    const step = trackerStep.toLowerCase().replace(/\s+/g, "-");
    return iconExists(step) ? icons[step] : null;
};

interface IProps {
    [key: string]: any;
    onNavigate: (step: Step) => void;
    currentScreen: Step;
    selectedSize: Mattress_Size;
    selectedFirmness: number;
    selectedCoolness: number;
    selectedBudget: number;
    selectedHeight: number | null;
    selectedWeight: number | null;
}

export class MattressMatchNav extends React.Component<IProps> {
    private readonly isComplete = (step: Step) => {
        switch (step) {
            case Step.SIZE:
            case Step.RESULTS:
                return true;
            case Step.FEEL:
                return this.props.selectedFirmness !== 0;
            case Step.BODY_TYPE:
                return (
                    this.props.selectedHeight !== defaults.ui.selectedHeight &&
                    this.props.selectedWeight !== defaults.ui.selectedWeight
                );
            case Step.COOL:
            case Step.BUDGET:
                return (
                    this.props[`selected${getStepNameFromIdx(step)}`] !==
                    defaults.ui[`selected${getStepNameFromIdx(step)}`]
                );
        }
    };
    private readonly buildButton = (step: Step) => {
        const currentScreen = this.props.currentScreen;
        const currentStep = Step[currentScreen];
        const stepIdx = Step[step];

        if (step === Step.SIZE) {
            return null;
        }

        const buttonClasses = classNames({
            [styles.button]: true,
            [styles.completed]: this.isComplete(step),
            [styles.active]: !this.isComplete(step) && stepIdx === currentStep,
        });
        const isDisabled =
            (Step[currentScreen] !== stepIdx && !this.isComplete(step)) ||
            step > currentScreen;
        const icon = getIcon(`menu-${slugify(stepIdx)}`);
        return (
            <button
                key={stepIdx}
                onClick={() => {
                    this.props.onNavigate(step);
                }}
                className={buttonClasses}
                data-key={step}
                disabled={isDisabled}
            >
                {icon && <SVG aria-hidden="true" src={icon} title={stepIdx} />}
                {getStepNameFromIdx(step)}
            </button>
        );
    };

    render() {
        const classes = classNames({
            [styles.nav]: true,
        });
        return (
            <>
                <nav className={classes}>
                    {stepArrayIdxs.map((step) => {
                        return this.buildButton(step);
                    })}
                </nav>
            </>
        );
    }
}
