import React from "react";
import classNames from "classnames";
import { TransitionGroup } from "react-transition-group";
import { defaults } from "../defaults";
import { Mattress_Size, mattressSpecsFromName } from "../constants";

import styles from "./MattressSize.module.scss";

interface IProps {
    onSelectSize: (size: Mattress_Size) => void;
}

interface IState {
    currentSize: Mattress_Size;
    currentPos: number;
}

export class MattressSelectorSize extends React.Component<IProps, IState> {
    sizeArray = Object.values(Mattress_Size).filter((v) => isNaN(Number(v)));

    public state: IState = {
        currentSize:
            this.sizeArray.find((size) => {
                return size === defaults.ui.selectedSize;
            }) || Mattress_Size.QUEEN,
        currentPos: Object.values(Mattress_Size).indexOf(
            defaults.ui.selectedSize || Mattress_Size.QUEEN,
        ),
    };

    private readonly prevSize = () => {
        const decrementPos = this.state.currentPos - 1;
        this.setState({
            currentPos: decrementPos,
            currentSize: Object.values(Mattress_Size)[decrementPos],
        });
    };

    private readonly nextSize = () => {
        const incrementPos = this.state.currentPos + 1;
        this.setState({
            currentPos: incrementPos,
            currentSize: Object.values(Mattress_Size)[incrementPos],
        });
    };

    componentWillUnmount() {
        this.props.onSelectSize(this.state.currentSize);
    }

    render() {
        const currentSizeClasses = classNames({
            [styles.sizeSelector]: true,
            [this.state.currentSize]: this.state.currentSize,
            [styles.sizeBorder]:
                this.state.currentSize !== Mattress_Size.CAL_SPLIT_KING,
        });

        return (
            <>
                <div className={styles.heading}>
                    <h1>Discover Your Perfect Tempur-Pedic®!</h1>
                    <p className={styles.instructions}>
                        To get started, select your preferred mattress size.
                    </p>
                </div>
                <div className={styles.sizeSelectorContainer}>
                    <div className={styles.widthMeasurement}>
                        {mattressSpecsFromName(this.state.currentSize).width}
                        &quot;
                    </div>

                    <button
                        className={styles.decrementButton}
                        onClick={this.prevSize}
                        disabled={this.state.currentSize === Mattress_Size.TWIN}
                    >
                        &ndash;
                    </button>
                    <TransitionGroup
                        className={currentSizeClasses}
                    ></TransitionGroup>
                    <button
                        className={styles.incrementButton}
                        onClick={this.nextSize}
                        disabled={
                            this.state.currentSize ===
                            this.sizeArray[this.sizeArray.length - 1]
                        }
                    >
                        +
                    </button>
                    <div className={styles.heightMeasurement}>
                        {mattressSpecsFromName(this.state.currentSize).height}
                        &quot;
                    </div>
                    <div className={styles.sizeLabel}>
                        {this.state.currentSize}
                    </div>
                </div>
            </>
        );
    }
}
