import React from "react";
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import { ILocation } from "tsi-common-react/src/models/location.interfaces";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import {
    nearbyStoreSelector,
    preferredLocationSelector,
} from "tsi-common-react/src/apps/common/selectors";
import iconStoreLocatorFooter from "../../svg/store_locator_footer.svg";

interface IOwnProps {}

interface IReduxProps {
    numStores: number;
    location: ILocation | null;
}

type IProps = IReduxProps & IOwnProps;

interface IState {}

class NearbyStoresAdContainer extends React.Component<IProps, IState> {
    private getAdText() {
        let fmtString: string;
        if (this.props.location) {
            fmtString = ngettext(
                "%(storeCount)s store near %(location)s",
                "%(storeCount)s stores near %(location)s",
                this.props.numStores,
            );
        } else {
            fmtString = ngettext(
                "%(storeCount)s store near you",
                "%(storeCount)s stores near you",
                this.props.numStores,
            );
        }
        const adCopy = interpolate(
            fmtString,
            {
                storeCount: `${this.props.numStores}`,
                location: this.props.location
                    ? `${this.props.location.formatted_address}`
                    : "",
            },
            true,
        );
        return adCopy;
    }

    render() {
        return (
            <div className="footer-widgets__offer">
                <SVG
                    className="footer-widget__icon"
                    src={iconStoreLocatorFooter}
                    title={gettext("Locate a Store Icon")}
                />
                <div className="footer-widget__content">
                    <h4>
                        <a
                            title={gettext("Find a Retailer")}
                            href="/find-a-retailer"
                            className="footer-widget__header"
                        >
                            {gettext("Feel it for yourself")}
                        </a>
                    </h4>
                    <span className="footer-widget__copy">
                        {this.getAdText()}
                    </span>{" "}
                    <a
                        title={gettext("Find a Retailer")}
                        href="/find-a-retailer"
                        className="text-link footer-widget__link"
                    >
                        {gettext("See List")}
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    const props: IProps = {
        numStores: nearbyStoreSelector(state).length,
        location: preferredLocationSelector(state),
        ...ownProps,
    };
    return props;
};

export const NearbyStoresAd = connect(mapStateToProps)(NearbyStoresAdContainer);
